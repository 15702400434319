<script>
import { DraftTypes } from '@/domain/drafts/draft';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { useSuperdocStore } from '@/stores/superdoc-store';

export default {
  name: 'DraftsEditorsRenderer',

  setup() {
    const harbourStore = useHarbourStore();
    const draftsStore = useDraftsStore();
    const superdocStore = useSuperdocStore();
    return {
      harbourStore,
      draftsStore,
      superdocStore,
    };
  },

  data() {
    return {};
  },

  computed: {
    draft() {
      return this.params.data;
    },
    isPdfType() {
      return this.draft.type === DraftTypes.Pdf;
    },
    isUserOwner() {
      return !this.draft.isShared;
    },
    editors() {
      return this.draft.writer;
    },
    hasEditors() {
      return this.editors.length > 0;
    },
    isSuperdoc() {
      return this.params.data.type === DraftTypes.SuperDoc;
    },
    isMore5Editors() {
      return this.editors.length > 5;
    },
    editorsToRender() {
      if (this.isMore5Editors) return this.editors.slice(0, 4);
      return this.editors;
    },
    otherEditors() {
      return this.editors.slice(4);
    },
    othersCount() {
      const others = this.otherEditors;
      return `+${others.length}`;
    },
    othersTooltipLabel() {
      const details = this.otherEditors.map((editor) => {
        return `<div>${this.getTooltipLabel(editor)}</div>`;
      });
      return details.join('');
    },
    canShare() {
      if (this.isPdfType) return false;
      const systemEmail = this.harbourStore.contextDict.systememail;
      const isWriter = this.draft.writer.includes(systemEmail);
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isWriter || isOrgAdmin;
    },
  },

  methods: {
    getProfileImageUrl(email) {
      const profile = this.harbourStore.profilePictures.find((i) => i.email === email);
      return profile?.profileImageUrl;
    },

    getInitial(email) {
      const name = this.getNameByEmail(email);
      if (name) return name[0].toUpperCase();
      else if (email) return email[0].toUpperCase();
      return '*';
    },

    getTooltipLabel(email) {
      const name = this.getNameByEmail(email);
      if (!name) return `(${email})`;
      return `${name} (${email})`;
    },

    getNameByEmail(email) {
      return this.draftsStore.emailsToNames[email] || null;
    },

    onAddButtonClick() {
      if (!this.canShare) return;

      if (this.isSuperdoc) return this.superdocStore.openSuperdocShareModal(this.draft, this);
      this.openCkShareModal({
        ckFileId: this.draft.ckFileId,
        name: this.draft.name,
      });
    },

    openCkShareModal({ ckFileId, name }) {
      this.$openCkeditorShareModal({
        props: {
          fileDisplayId: ckFileId,
          agreementName: name,
        },
      });
    },

  },
};
</script>

<template>
  <div class="draft-editors-column">
    <div class="draft-editors-column__container">
      <template v-if="hasEditors">
        <div
          v-for="(editor, index) in editorsToRender"
          :key="index"
          class="draft-editors-column__editor"
          :class="[`draft-editors-column__editor--${index + 1}`]">
          <b-tooltip
            :label="getTooltipLabel(editor)"
            :append-to-body="true"
            type="is-dark"
            class="draft-editors-column__tooltip">
            <div class="draft-editors-column__image">
              <img
                v-if="getProfileImageUrl(editor)"
                :src="getProfileImageUrl(editor)"
                class="draft-editors-column__img" />
              <div v-else class="draft-editors-column__initials">
                {{ getInitial(editor) }}
              </div>
            </div>
          </b-tooltip>
        </div>

        <div class="draft-editors-column__others" v-if="isMore5Editors">
          <b-tooltip
            :append-to-body="true"
            :multilined="true"
            type="is-dark"
            class="draft-editors-column__others-tooltip">
            <div class="draft-editors-column__others-count">
              <div class="draft-editors-column__others-count">{{ othersCount }}</div>
            </div>
            <template v-slot:content>
              <div
                class="draft-editors-column__others-tooltip-content"
                v-html="othersTooltipLabel"></div>
            </template>
          </b-tooltip>
        </div>
      </template>

      <div
        class="draft-editors-column__add-button"
        :class="{'draft-editors-column__add-button--disabled': !canShare}"
        @click="onAddButtonClick">
        <i class="fa-solid fa-plus"></i>
      </div>

    </div>
  </div>
</template>

<style lang="postcss" scoped>
.draft-editors-column {
  padding: 0 5px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
  }

  &__editor {
    display: inline-flex;
    position: relative;

    &--1 {
      z-index: 0;
    }
    &--2 {
      z-index: 1;
      margin-left: -6px;
    }
    &--3 {
      z-index: 2;
      margin-left: -6px;
    }
    &--4 {
      z-index: 3;
      margin-left: -6px;
    }
    &--5 {
      z-index: 4;
      margin-left: -6px;
    }

    :deep(.b-tooltip .tooltip-trigger) {
      display: inline-flex;
    }
  }

  &__add-button {
    --size: 28px;
    font-size: 16px;
    color: #333;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    flex-shrink: 0;
    overflow: hidden;
    cursor: pointer;
    transition: all .2s;

    &:hover {
      background-color: #DBDBDB;
    }

    &--disabled {
      color: #ccc;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__image {
    --size: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: #849cb8;
    border: 1px solid #DBDBDB;
    flex-shrink: 0;
    overflow: hidden;
  }

  &__img {
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
  }

  &__initials {
    font-size: 14px;
    color: #fff;
    line-height: 1;
  }

  &__others {
    display: inline-flex;
    position: relative;
    z-index: 5;
    margin-left: -6px;

    :deep(.b-tooltip .tooltip-trigger) {
      display: inline-flex;
    }
  }

  &__others-count {
    --size: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: #d1dbf4;
    border: 1px solid #DBDBDB;
    flex-shrink: 0;
    overflow: hidden;
  }

  &__others-count-text {
    font-size: 12px;
    color: #333;
    line-height: 1;
  }
}
</style>

<style lang="postcss">
.draft-editors-column__tooltip .tooltip-content,
.draft-editors-column__others-tooltip .tooltip-content {
  font-size: 12px;
  color: #fff;
}

.draft-editors-column__others-tooltip-content {
  text-align: left;
  white-space: nowrap;
  line-height: 1.4;

  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
