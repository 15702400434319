import {
  DialogProgrammatic as Dialog,
} from 'buefy';
import { _ } from 'lodash';
import Vue, { ref } from 'vue';

import { useBlock } from '@/pages/Workflows/composables/wf-use-block';
// Import the available block versions for our versioning system
import BlockVersions from '@/components/WorkflowBlocks/block-versions.js';


export function useWorkflow(workflow = null) {
  const wf = workflow;
  const id = ref(workflow ? workflow?.id : null)

  const todaysDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const name = ref(workflow?.name ||  `New workflow ${todaysDate}`);
  const description = ref(workflow?.description || null);

  const group = ref(workflow?.workflow_group || null);
  const publishState = ref(workflow?.publish_state || false);

  const creator = ref(workflow?.creator_email || null);

  const created = ref(workflow?.created_time || null);
  const lastUpdater = ref(workflow?.last_updater || null);

  const updatedTime = ref(workflow?.updated_time  || null);

  const sampleTemplate = ref(null);

  const defaultGlobals = { 'general-contacts': { value: [] }, 'pending-approval-ids': [], };
  const globals = ref(workflow?.globals || defaultGlobals);
  const links = ref([]);
  const isOrgWide = ref(workflow?.is_org_wide || false);

  // Required vars
  const webhook = ref(workflow?.webhook || null);
  const workflowLink = ref(workflow?.link || "Available after publish");
  const hasChangedSincePublish = ref(false);

  const insertValidationStrategy = {
    sendEmailForSignature: (currentPath, parentBlock, blocks) => {
      // This should never happen, but if it does for any reason, we should not block the insertion
      if (!currentPath) {
        return true;
      }
      
      const hasSendEmailForSignature = currentPath.some(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.find(b => b.id === blockId);
        return block.key === 'sendEmailForSignature';
      });

      const parentBlockIndex = currentPath.findIndex(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        return blockId === parentBlock.id;
      });
      const blocksAfter = currentPath.slice(parentBlockIndex + 1);
      const blocksBefore = currentPath.slice(0, parentBlockIndex + 1);
    
      const hasRequestApprovalAfter = blocksAfter.some(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.find(b => b.id === blockId);
        return block.key === 'requestApproval';
      });
      const hasDocumentReadyForSignatureAfter = blocksAfter.some(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.find(b => b.id === blockId);
        return block.key === 'documentReadyForSignature';
      });
      const hasDocumentCompletedBefore = blocksBefore.some(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.find(b => b.id === blockId);
        return block.key === 'documentCompleted';
      });

      return (
        !hasSendEmailForSignature &&
        !hasRequestApprovalAfter &&
        !hasDocumentReadyForSignatureAfter &&
        !hasDocumentCompletedBefore
      );
    },
    requestApproval: (currentPath, parentBlock, blocks) => {
      // We can't have a RA block after a send email for signature block in the given path
      const sendEmailForSignatureIndex = currentPath.findIndex(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.find(b => b.id === blockId);
        return block.key === 'sendEmailForSignature';
      })
  
      if (sendEmailForSignatureIndex === -1) {
        return true;
      }
  
      const parentBlockIndex = currentPath.findIndex(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        return blockId === parentBlock.id;
      });
  
      return parentBlockIndex < sendEmailForSignatureIndex;
    },
    saveFile: (currentPath, parentBlock, blocks) => {
      // We can only add a save file block after a document completed event
      const documentCompletedIndex = currentPath.findIndex(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.find(b => b.id === blockId);
        return block.key === 'documentCompleted';
      });
  
      if (documentCompletedIndex === -1) {
        return false;
      }
  
      const parentBlockIndex = currentPath.findIndex(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        return blockId === parentBlock.id;
      });
  
      return parentBlockIndex >= documentCompletedIndex;
    },
    default: () => true,
  }

  /* Error handling */
  const errors = ref([]);
  const addError = (error) => {
    const { id, message } = error;
    errors.value = errors.value.filter((e) => e.id !== error.id);
    errors.value.push({ id, message });
  }
  const removeError = (id) => {
    errors.value = errors.value.filter((e) => e.id !== id);
  }

  const getBlockDefinition = async (key, version = null) => {
    let blockDefinition = null;
    const blockVersionEntry = BlockVersions[key];

    // Get all version for this particular type (key) of block
    const allowedBlockVersions = blockVersionEntry?.versions;
    if (!allowedBlockVersions) throw new Error(`[use-workflow] Block versions not found for ${key}`);

    // Attempt to pull the block definition for the specified version
    // Pulls the latest version if no version is specified
    if (!version) blockDefinition = [...allowedBlockVersions].pop();
    else blockDefinition = allowedBlockVersions.find((block) => block.name === version);
    if (!blockDefinition) throw new Error(`[use-workflow] Block version ${version} not found for ${key}`);

    const definition = await import(`./workflow-blocks/${key}/${key}-${blockDefinition.name}.js`);
    if (!definition || !definition.block) throw new Error(`[use-workflow] Block definition not found for ${key}`);
  
    // Returns the block definition for this block's version, or the latest version if no version is specified
    return _.cloneDeep(definition.block);
  };

  const createBlock = async ({ key, parentBlock = null, version = null }) => {
    const blockDef = await getBlockDefinition(key, version);
    const newBlock = useBlock(blockDef, parentBlock, wf);
    const numBlocksThisType = blocks.value.filter((block) => block.key === key).length + 1;
    newBlock.friendlyId = blockDef.key + numBlocksThisType;
    newBlock.indexOfType = numBlocksThisType;
    return newBlock;
  }

  const initNewWorkflow = async (roots, groupParam, { shouldRearrangeEventLabels } = { shouldRearrangeEventLabels: true }) => {
    group.value = groupParam;
    blocks.value = [];
    for (const item of roots) {
      const newBlock = await createBlock({ key: item });
      newBlock.setAsRoot(true);
      newBlock.init(newBlock);
      blocks.value.push(newBlock);
    };

    // This is needed when duplicating a workflow
    // Otherwise we could have duplicate event labels
    if (shouldRearrangeEventLabels) {
      await rearrangeEventLabels();
    }
  
    return await create();
  };

  const insertBlock = async (key, outcomeBlock) => {
    const ownerBlock = blocks.value.find((block) => block.id === outcomeBlock.owner);
    if (!isValidInsertion(key, ownerBlock, outcomeBlock.outcomeIndex)) {
      return;
    }
  
    const outcomeId = outcomeBlock.outcomeIndex;
    const previousChildId = outcomeBlock.child?.id;

    // Create the new block
    const newBlock = await createBlock({ key, parentBlock: ownerBlock });

    // Set the new block as the child of the outcome
    ownerBlock.setOutcome(outcomeId, newBlock);
    newBlock.init(newBlock);

    // Update the level of the previous child which is now down one
    const previousChildBlock = blocks.value.find((block) => block.id === previousChildId);
    if (previousChildBlock) {
      // Set the previous child as the child of the new block
      newBlock.setOutcome(0, previousChildBlock);

      // Update the previous child's parent to the new block
      previousChildBlock.setParent(ref(newBlock));
    }

    // Push the new block to our master list
    blocks.value.push(newBlock);
    return newBlock;
  };
 
  const removeBlock = (id) => {
    return new Promise((resolve) => {
      const blockToRemove = blocks.value.find((block) => block.id === id);
      if (!blockToRemove || blockToRemove?.isRoot) return;
  
      const children = blockToRemove.outcomes.filter((outcome) => outcome.child);
      const possibleOutcomes = blockToRemove.outcomes;
  
      const parentBlock = blockToRemove.parent;
      if (!parentBlock) return;
  
      // If no children, we can remove the block safely
      if (!children.length) {
        const outcome = parentBlock.outcomes.find(
          (outcome) => outcome.childId === blockToRemove.id,
        );
        blocks.value = blocks.value.filter(
          (block) => block.id !== blockToRemove.id,
        );
        outcome.child = null;
        resolve();
      }
  
      // If we have one child but also the parent only has one outcome, we can also remove the block safely
      else if (possibleOutcomes.length === 1) {
        const outcome = parentBlock.outcomes.find(
          (outcome) => outcome.childId === blockToRemove.id,
        );
        const blockChild = blockToRemove.outcomes[0].child;
        blockChild.setParent(ref(parentBlock));
        parentBlock.setOutcome(outcome.outcomeIndex, blockChild);
  
        blocks.value = blocks.value.filter(
          (block) => block.id !== blockToRemove.id,
        );
        resolve();
      } else if (possibleOutcomes.length > 1) {
        Dialog.confirm({
          title: 'Deleting block',
          message: 'This will remove all blocks under this block',
          confirmText: 'Delete',
          type: 'is-danger',
          onConfirm: function () {
            // Traverse the tree and remove all children
            const outcome = parentBlock.outcomes.find(
              (outcome) => outcome.childId === blockToRemove.id,
            );
            parentBlock.setOutcome(outcome.outcomeIndex, null);
            const ids = treeTraverseGetIds(blockToRemove);
            blocks.value = blocks.value.filter(
              (block) => !ids.includes(block.id),
            );            
            resolve();
          },
        });
      }
    })
  };

  const isValidInsertion = (key, parentBlock, outcomeId) => {
    const paths = findApprovedPaths();
    const currentPath = paths.find(path => {
      const isParentBlockInThePath = path.find(blockAndOutcomeId => {
        return `${parentBlock.id}:${outcomeId}` === blockAndOutcomeId;
      });
      return isParentBlockInThePath;
    });

    const fn = insertValidationStrategy[key] || insertValidationStrategy.default;
    return fn(currentPath, parentBlock, blocks.value);
  };

  const findApprovedPaths = () => {
    const allPaths = findPaths(blocks.value[0]);
    const approvedPaths = [];

    for (const path of allPaths) {
      let isApproved = true;
      for (const blockIdAndOutcome of path) {
        const [blockId, outcomeId] = blockIdAndOutcome.split(':');
        const block = blocks.value.find((block) => block.id === blockId);
        if (block.key === 'requestApproval' && outcomeId === '1') {
          isApproved = false;
        }
        if (block.key === 'requestApproval' && outcomeId === '0') {
          isApproved = true;
        }
      }

      if (isApproved) {
        approvedPaths.push(path);
      }
    }
    return approvedPaths;
  }

  const findFirstApprovalPath = (block) => {
    const paths = findPaths(block);
    for (const path of paths) {
      const approvalBlock = path.find(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.value.find(b => b.id === blockId);
        return block.key === 'requestApproval';
      });

      if (approvalBlock) {
        return path;
      }
    }
  }

  const findPaths = (root) => {
    const allPaths = [];
    const dfs = (node, path) => {
      if (!node) {
        allPaths.push(path);
        return;
      }

      const outcomes = node.outcomes;
      for (const outcome of outcomes) {
        dfs(outcome.child, [...path, `${node.id}:${outcome.outcomeId}`]);
      }
    };

    dfs(root, []);
    return allPaths;
  }

  const reorderBlock = (blockId, outcomeBlock) => {
    const draggedBlock = blocks.value.find((block) => block.id === blockId);
    if (!draggedBlock) return;

    const newOwnerBlock = blocks.value.find((block) => block.id === outcomeBlock.owner);
    const outcomeIdInNewOwner = outcomeBlock.outcomeIndex;
    const draggedBlockOldOutcomeId = draggedBlock.parent.outcomes.findIndex((outcome) => outcome.child?.id === blockId);
    const blockInOutcomeBeforeDrop = outcomeBlock.child;
    const draggedBlocksChild = draggedBlock.outcomes[0]?.child;

    // Set the destination to null to avoid circular references. The previous ref is in blockInOutcomeBeforeDrop
    newOwnerBlock.setOutcome(outcomeIdInNewOwner, null);

    // When a block moves, we need to:
    // 1. Check special case when the move is within the same parent, new outcome
    // 2. Update the dragged block's outcome to the block that we overrode & update the overriden block's parent
    // 3. If the dragged block had a child, update that child's parent to the dragged block's previous parent (before drag)
    // 4. Update the dragged block's parent to the new owner
    // 5. Update the new owner's outcome to the dragged block
    // 6. Update the dragged block's outcome to the block that we overrode

    // 1. Check special case when the move is within the same parent, new outcome
    const outcomeIndexInOwner = newOwnerBlock.outcomes.findIndex((outcome) => outcome.child?.id === blockId);
    if (outcomeIndexInOwner > -1) newOwnerBlock.setOutcome(outcomeIndexInOwner, null);

    // 2. Update the dragged block's outcome to the block that we overrode
    draggedBlock.parent.setOutcome(draggedBlockOldOutcomeId, draggedBlocksChild);
    blockInOutcomeBeforeDrop?.setParent(ref(draggedBlock));

    // 3. If the dragged block had a child, update that child's parent to the dragged block's previous parent (before drag)
    draggedBlocksChild?.setParent(ref(draggedBlock.parent));

    // 4. Update the dragged block's parent to the new owner
    draggedBlock.setParent(ref(newOwnerBlock));

    // 5. Update the new owner's outcome to the dragged block
    newOwnerBlock.setOutcome(outcomeIdInNewOwner, draggedBlock);

    // 6. Update the dragged block's outcome to the block that we overrode
    draggedBlock.setOutcome(0, blockInOutcomeBeforeDrop);

    return draggedBlock;
  };

  const rearrangeEventLabels = async () => {
    await rearrangeDocumentReadyForSignature();
    await rearrangeDocumentCompleted();
  }

  const rearrangeDocumentReadyForSignature = async () => {
    const currentReadyForSignatureBlocks = blocks.value.filter(b => b.key === 'documentReadyForSignature').map(b => b.id);      
    for (const eventLabelId of currentReadyForSignatureBlocks) {
      await removeBlock(eventLabelId);
    }
  
    const approvedPaths = findApprovedPaths();
    for (const approvedPath of approvedPaths) {
      const lastBlockId = approvedPath[approvedPath.length - 1].split(':')[0];
      const lastBlock = blocks.value.find(b => b.id === lastBlockId);
      let block = lastBlock;
    
      while (block) {
        // Since we start from the last block in the path, the first RA block we find gets the ready for signature event
        if (block.key === 'requestApproval') {
          const hasReadyForSignature = block.outcomes.some(o => o.child?.key === 'documentReadyForSignature');
          if (!hasReadyForSignature) {
            await insertBlock('documentReadyForSignature', block.outcomes[0]);
          }
          break;
        }
      
        // When we find an approval path for the block, we go to the opposite outcome and add a ready for signature event
        const approvalPath = findFirstApprovalPath(block);
        if (approvalPath) {
          const outcome = approvalPath[0].split(':')[1];
          const oppositeOutcome = parseInt(outcome) === 0 ? 1 : 0;
          const hasReadyForSignature = block.outcomes[oppositeOutcome]?.child?.key === 'documentReadyForSignature';
          if (!hasReadyForSignature) {
            await insertBlock('documentReadyForSignature', block.outcomes[oppositeOutcome]);
          }
  
          break;
        } else {
          block = block.parent;
        }
      }

      // This means we reached the top of the workflow and there are no approval blocks
      // So we assume the document is ready for signature as soon as the workflow starts
      if (!block) {
        const firstBlock = blocks.value[0];
        const hasReadyForSignature = firstBlock.outcomes[0]?.child?.key === 'documentReadyForSignature';
        if (!hasReadyForSignature) {
          await insertBlock('documentReadyForSignature', firstBlock.outcomes[0]);
        }
      }
    }
  };

  const rearrangeDocumentCompleted = async () => {
    const currentDocumentCompletedBlocks = blocks.value.filter(b => b.key === 'documentCompleted');
  
    if (currentDocumentCompletedBlocks.length === 0) {
      // This means it's an existing workflow pre launch of this feature
      // We need to initialize the document completed events
      await addDocumentCompletedEventLabels();
    } else {
      const hasInvalidOrMissingDocumentCompleted = checkForInvalidDocumentCompletedPlacement();
      if (hasInvalidOrMissingDocumentCompleted) {
        const documentCompletedEvents = blocks.value.filter(b => b.key === 'documentCompleted');
        for (const documentCompletedEvent of documentCompletedEvents) {
          await removeBlock(documentCompletedEvent.id);
        }
        await addDocumentCompletedEventLabels();
      }
    }
  };

  const addDocumentCompletedEventLabels = async () => {
    for (const approvedPath of findApprovedPaths()) {
      const sendEmailForSignatureBlockId = approvedPath.find(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.value.find(b => b.id === blockId);
        return block.key === 'sendEmailForSignature';
      })?.split(':')[0];

      if (sendEmailForSignatureBlockId) {
        const sendEmailForSignatureBlock = blocks.value.find(b => b.id === sendEmailForSignatureBlockId);
        if (!hasDownstreamDocumentCompleted(sendEmailForSignatureBlock)) {
          await insertBlock('documentCompleted', sendEmailForSignatureBlock.outcomes[0]);
        }
        continue;
      }

      const lastBlockInPath = blocks.value.find(b => b.id === approvedPath[approvedPath.length - 1].split(':')[0]);
      const leftOutcome = lastBlockInPath?.outcomes[0];
      const rightOutcome = lastBlockInPath?.outcomes[1];

      if (leftOutcome) {
        if (!leftOutcome.child) {
          await insertBlock('documentCompleted', leftOutcome, 'Add block menu');
        }
      }

      if (rightOutcome) {
        if (!rightOutcome.child) {
          await insertBlock('documentCompleted', rightOutcome, 'Add block menu');
        }
      }
    }
  };

  const checkForInvalidDocumentCompletedPlacement = () => {
    let hasInvalidDocumentCompleted = false;
  
    for (const approvedPath of findApprovedPaths()) {
      // This is a workflow that already has the document completed events
      // We need to make sure their placements are valid
      const documentCompletedEvents = approvedPath.filter(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.value.find(b => b.id === blockId);
        return block.key === 'documentCompleted';
      }).map(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.value.find(b => b.id === blockId);
        return block;
      });

      if (documentCompletedEvents.length != 1) {
        hasInvalidDocumentCompleted = true;
      }
    
      for (const documentCompletedEvent of documentCompletedEvents) {
        const hasUpstreamSendEmailForSignature = documentCompletedEvent.getUpstreamData().some(b => b.key === 'sendEmailForSignature');
        const hasUpstreamDocumentReadyForSignature = documentCompletedEvent.getUpstreamData().some(b => b.key === 'documentReadyForSignature');

        if (!hasUpstreamDocumentReadyForSignature && !hasUpstreamSendEmailForSignature) {
          hasInvalidDocumentCompleted = true;
        }
      }
    }

    return hasInvalidDocumentCompleted
  }

  const hasDownstreamDocumentCompleted = (block) => {
    return findPaths(block).some(path => {
      return path.some(blockAndOutcomeId => {
        const [blockId] = blockAndOutcomeId.split(':');
        const block = blocks.value.find(b => b.id === blockId);
        return block.key === 'documentCompleted';
      });
    });
  }

  const treeTraverseGetIds = (node, nodeIds = []) => {
    if (node === null) {
      return;
    }

    nodeIds.push(node.id);
    node.outcomes.forEach((outcome) => {
      treeTraverseGetIds(outcome.child, nodeIds);
    });

    return nodeIds;
  };

  const workflowHasValidBlockTypes = (blockTypes) => {
    for (const blockType of blockTypes) {
      if (!(blockType in BlockVersions)) return false;
    };

    return true;
  };

  const blocks = ref([]);
  const loadBlocks = async (isCopying) => {

    let currentBlocks = wf.blocks;
    const blockTypes = new Set(currentBlocks.map((block) => block.key));

    if (!workflowHasValidBlockTypes(blockTypes)) return;
    if (currentBlocks) {
      const initialBlock = currentBlocks[0];
      const version = initialBlock.general_settings.version;
      const newBlock = await createBlock({ key: initialBlock.key, version });
      const originalId = currentBlocks[0].id;
      newBlock.load(initialBlock, isCopying, originalId);
      newBlock.init(newBlock);
      blocks.value.push(newBlock);
      await insertLoadedBlock(newBlock, isCopying, originalId);
    }

    await rearrangeEventLabels();
    return true;
  }

  const insertLoadedBlock = async (currentBlock, isCopying, originalId) => {
    // Restore a block that was loaded from the backend
    let id = originalId || currentBlock.id;
    const loadedBlock = wf.blocks.find((block) => block.id === id);
    const blocksToProcess = [];

    for (const outcome of currentBlock.outcomes) {
      const loadedOutcome = loadedBlock.outcomes[outcome.outcomeId];
      const childBlock = wf.blocks.find((block) => block.id === loadedOutcome.childId);

      if (!childBlock || !loadedOutcome) continue;
      const originalId = childBlock.id.value;

      const newChild = await createBlock({ key: childBlock.key });
      newChild.load(childBlock, isCopying, originalId);
      newChild.init(newChild);

      newChild.setParent(currentBlock);
      currentBlock.setOutcome(outcome.outcomeId, newChild);
      blocks.value.push(newChild);
      blocksToProcess.push(newChild);
    };

    // Build the tree recursively
    await Promise.all(blocksToProcess.map(async (block) => await insertLoadedBlock(block, isCopying, block.id.value)));
  }

  const generateSaveObject = () => {
    return {
      id: id.value,
      name: name.value,
      description: description.value,
      workflow_group: group.value,
      webhook: webhook.value,
      publish_state: publishState.value,
      globals: globals.value,
      blocks: blocks.value.map((block) => block.save()),
    }
  }

  const create = async () => {
    const saveObject = generateSaveObject();
    const result = await Vue.prototype.$harbourData.post('/api/workflows', saveObject);
    id.value = result.data.id;
    created.value = result.data.created_time;
  }

  const save = async (saveParams) => {
    const saveObject = generateSaveObject();
    
    // Track that this workflow has changed since the last publish
    hasChangedSincePublish.value = true;

    const options = { workflow: saveObject, changes: saveParams };
    const result = await Vue.prototype.$harbourData.put(`/api/workflows/${id.value}`, options);

    const { last_updater, updated_time } = result.data;
    lastUpdater.value = last_updater;
    updatedTime.value = updated_time;
  };

  const publish = async (workflowData) => {
    workflowData.publishState = true;

    // Since we're publishing, we can reset the hasChangedSincePublish flag
    hasChangedSincePublish.value = false;

    const saveObject = generateSaveObject();
    const changes = {
      current_change: { "publish_state": true },
      change_note: "Published workflow",
      source: "Publish button",
    }
    const options = { changes,  workflow: saveObject };
    await Vue.prototype.$harbourData.post(`/api/workflows/publish/${id.value}`, options);
  }

  const linkWorkflowToObject = async (linkType, object_id, mapping) => {
    const options = {
      workflow_id: id.value,
      object_type: linkType,
      object_id: object_id,
      mapping,
    }

    const result = await Vue.prototype.$harbourData.post('/api/workflow-connections', options);
  }

  const unlinkWorkflowFromObject = async (linkType, object_id) => {    
    const options = {
      workflow_id: id.value,
      link_type: linkType,
      object_id: object_id,
      direction: 'remove'
    }

    const result = await Vue.prototype.$harbourData.post('/api/workflows/create-link', options);
  }

  const checkForApprovals = () => {
    const rootBlock = blocks.value[0];
    const rootId = rootBlock.id;
    const approvalBlocks = blocks.value.filter((block) => block.key === 'requestApproval');
    for (const block of approvalBlocks) {
      //check if rootId is in this block
      const variables = block.variables['blocks-to-approve'].value;
      if (variables.includes(rootId)) return true;
    }
    return false;
  };

  const rename = async (newName) => {
    name.value = newName;
    await save({
      source: 'Workflow menu renderer',
      current_change: { name: newName },
      change_note: `Renamed workflow to ${newName}`,
    });
  }

  const expose = {
    id,
    name,
    description,
    creator,
    created,
    lastUpdater,
    updatedTime,
    isOrgWide,
    group,
    publishState,

    sampleTemplate,
    globals,
    links,
    webhook,
    workflowLink,
    hasChangedSincePublish,

    // Blocks
    blocks,
    
    save,
    create,
    rename,
    publish,
    loadBlocks,
    getBlockDefinition,
    generateSaveObject,

    // Errors
    errors,
    addError,
    removeError,

    // Init
    initNewWorkflow,
    createBlock,
    insertBlock,
    removeBlock,
    reorderBlock,
    rearrangeEventLabels,
    checkForApprovals,

    // Linking
    linkWorkflowToObject,
    unlinkWorkflowFromObject,
  }

  return expose;
};