import axios from 'axios';
import { ModalProgrammatic as Modal, ToastProgrammatic as Toast } from 'buefy';
import dateFormat from 'dateformat';
import { defineStore } from 'pinia';
import Vue from 'vue';

import { DEFAULT_COLUMNS } from '@/utils/helpers/default-columns.js';
import { textDeSanitize } from '@/utils/helpers/functions.js';

// Component imports
import PdfPreview from '@/pages/Dashboard/components/PdfPreview.vue';
import TransferOwnership from '@/pages/Dashboard/components/TransferOwnership.vue';

// Other stores
import { useCkeditorStore } from '@/components/Ckeditor/stores/ckeditor-store';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';
import { useAnalyticsStore } from './analytics-store';
import { useHarbourStore } from './harbour-store';
import { useLibraryStore } from './library-store';
import { useWorkflowsStore } from './workflows-store';
import { useSuperdocStore } from './superdoc-store';

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    harbourStore: useHarbourStore(),
    libraryStore: useLibraryStore(),
    analyticsStore: useAnalyticsStore(),
    ckeditorStore: useCkeditorStore(),
    settingsStore: useSettingsPageStore(),
    workflowsStore: useWorkflowsStore(),
    superdocStore: useSuperdocStore(),
    gridReference: null,
    columnDefs: [],
    gridApi: null,
    gridColumnApi: null,
    currentOpenView: null, // dashboard, awaiting-my-review, signed-by-me
    status: 'Initializing...',
    title: 'All contracts',
    awaitingNodesToRemove: new Set(),
    MAX_LINKS: 20000,
    initialLinks: [],
    lastUpdate: null,

    // details grid
    detailsActiveView: {},

    // link totals
    totalLinks: 0,
    totalCompleted: 0,
    totalPending: 0,

    // Live sessions
    liveSessions: [],
    liveSessionExpiries: {},

    hasLoadedLinksSincePageLoad: false,
    isInitialLinksReady: false,
    signerBrands: [
      {
        domain: 'gmail.com',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/512px-Gmail_icon_%282020%29.svg.png?20221017173631',
      },
    ],
    columnState: [],
    showAnalytics: false,

    // Filters
    mylinksCompleteState: 'ALL',
    adminState: 'defaultview',
    dashboardFiltersOptions: [
      { name: 'linkname', display_name: 'Link name' },
      { name: 'inputs', display_name: 'Agreement inputs' },
      { name: 'agreementlink', display_name: 'Agreement link' },
    ],
    textFilterValue: '',
    textFilterName: 'linkname',
    creatorEmailFilterValue: '',
    linksOriginalCreators: '',
    setSelectedFilterName: '',
    myLinksFilterSelectedAgreementId: null,
    myLinksFilterAvailableOrganizationalAgreements: [],

    currentFilterTitle: 'All',
    numDisplayedLinks: 0,
    getRowId: (params) => params.data.id,

    columnKeysToSave: ['colId', 'hide', 'width'],
    columnStateToSave: [],
    initialColsData: DEFAULT_COLUMNS,
    savedColsData: [],

    isSearchingServer: false,
    quickFilterTimeout: null,
    lastSearch: null,
    isLoadingView: false,

    // Link sessions
    linkSessions: [],
    inputSearchMatches: new Set(),

    // Dashboard previews
    isPreviewModalActive: false,
    previewLink: null,
    previewURL: null,
    previewDataURL: null,
    previewDownloadButton: null,
    previewData: null,
    lastDashboardGroupId: null,

    // Right sidebar
    selectedLink: null,

    // Groups selected in Link created modal
    linksSelectedGroups: {},
  }),

  getters: {
    getTitle: (state) => {
      if (state.currentFilterTitle === 'All') return 'All contracts';
      else if (state.currentFilterTitle === 'Pending') return 'Pending contracts';
      else if (state.currentFilterTitle === 'Completed') return 'Completed contracts';
    },
  },

  actions: {
    // Apply user settings to filters
    setSavedDashboardSettings() {
      this.mylinksCompleteState = 'ALL';
    },

    onPdfPreviewClose() {
      this.isPreviewModalActive = false;
      this.previewLink = null;
      this.previewURL = null;
      this.previewDownloadButton = null;
      this.previewData = null;
      this.previewDataURL = null;
    },

    getFirstSubmissionAsset() {
      const linkAssets = this.previewLink?.submissions;
      if (!linkAssets || !linkAssets.length) return null;
      const linkAssetsFirstAsset = linkAssets[0];
      return linkAssetsFirstAsset;
    },

    pdfPreviewEditClick(instance) {
      if (!instance || !this.previewLink) return;
      this.previewLink.parent = instance.proxy;

      if (this.previewLink.superdocId) {
        this.superdocStore.openSuperdocFromDrafts({
          parentComponent: instance,
          superdocId: this.previewLink.superdocId,
          agreementId: this.previewLink.agreementId,
          linkDisplayId: this.previewLink.id,
        });
        return;
      };

      this.harbourStore.openLinkInEditor(this.previewLink);
      this.onPdfPreviewClose();
    },

    async getPdfPreviewData() {
      const result = await axios({
        url: this.previewURL,
        method: 'GET',
        responseType: 'arraybuffer',
      });

      const blob = new Blob([result.data], { type: 'application/pdf' });
      const blobURL = URL.createObjectURL(blob);
      this.previewDataURL = blobURL;
    },

    async handleDownloadWordDoc(instance) {
      const draftId = this.previewLink.ckeditorAgreementId;
      Vue.prototype.$openCkeditorModal({
        props: {
          isAgreementEditorWorkflow: false,
          hasPolling: false,
          fileVersionDisplayId: draftId,
          syncComplete: true,
          parent: instance.proxy,
          isDownloadOnly: true,
        },
      });
    },

    triggerDownload(url, name, extension) {
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${name}.${extension}`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadLink.href);
    },

    async pdfPreviewDownloadClick(instance) {
      if (!this.previewLink) return;

      const isCompleted = this.isLinkCompleted(this.previewLink);

      // If unsigned CK based, need to download WORD
      // Otherwise, download PDF
      if (this.previewLink.ckeditorAgreementId && !isCompleted) {
        this.handleDownloadWordDoc(instance);
      } else {
        this.triggerDownload(this.previewDataURL, this.previewLink.title, 'pdf');
      }

      // Let the user know the download has started
      Toast.open({
        duration: 3000,
        message: `&#11015;&nbsp;&nbsp;Starting your download now...`,
        position: 'is-bottom',
        type: 'is-black',
      });
    },

    isLinkCompleted(link) {
      const linkToCheck = link || this.previewLink;
      const auth = linkToCheck.auth_method?.authmode;
      if (auth !== 'EMAILS') return linkToCheck.submissions?.length > 0;
      return (
        linkToCheck.email_recipients_completed?.length === linkToCheck.email_recipients?.length
      );
    },

    openCompletedDocPdfPreview(asset, link) {
      this.previewLink = link;
      this.previewURL = `/asset?cb=${Date.now()}&id=${asset.id}&agreelinkid=${link.id}`;
      this.getPdfPreviewData();
      this.isPreviewModalActive = true;
    },

    openLinkBasedPdfPreview(linkObject) {
      this.previewLink = linkObject;
      const isCompleted = this.isLinkCompleted(linkObject);

      // Unsigned URL
      this.previewURL = `/agreepdfunsigned/${this.previewLink.id}`;

      // Is this completed? If so, get the final signed URL instead
      // This is the same regardless of PDF vs CK
      if (isCompleted) {
        const firstAsset = this.getFirstSubmissionAsset();
        if (firstAsset)
          this.previewURL = `/agreepdf/${firstAsset.id}/agreelink/${this.previewLink.id}`;
      }

      this.getPdfPreviewData();
      this.isPreviewModalActive = true;
    },

    async openCkDocPreview(linkObject, instance, caller) {
      this.previewLink = linkObject;

      const isCompleted = this.isLinkCompleted(linkObject);
      if (isCompleted) {
        this.openLinkBasedPdfPreview(linkObject);
        return;
      }

      this.isLoadingView = true;
      const htmlData = await this.ckeditorStore.getCkeditorTemplateHtml(
        linkObject.agreement_id,
        linkObject.id,
      );
      const emailsLink = linkObject.authMethod?.authmode === 'EMAILS';
      const agreementInProgress = linkObject.emailRecipientsCompleted?.length > 0;

      const buttonText = emailsLink && agreementInProgress ? null : 'Edit document';
      Vue.prototype.$openCkeditorHtmlViewer({
        props: {
          htmlData,
          parent: caller,
          editButtonText: buttonText,
          source: 'library',
          showRedlineButtons: true,
        },
        events: {
          'download-click': () => {
            this.ckeditorStore.downloadCurrentDocx(htmlData, linkObject.title);
          },
          'edit-document': () => {
            this.pdfPreviewEditClick(instance);
          },
        },
      });

      this.isLoadingView = false;
    },

    async getMyLinkDataDetailsRequest({ linkDisplayId, ckeditorFileId = null, signal = null }) {
      const url = 'data?agreebuilder-getmylinkdatadetails';
      const payload = {
        requesttype: 'agreebuilder-getmylinkdatadetails',
        agreedisplayid: linkDisplayId,
        ckeditorfileid: ckeditorFileId,
      };
      const res = await this.$harbourData.post(url, payload, null, { signal });
      return res.data;
    },

    // Get the latest column data. Used by the column menu.
    updateColumns() {
      this.columnState = this.gridColumnApi.getColumnState();
    },

    updateCounts() {
      if (!this.gridApi) return;
      let totalLinks = 0;
      let totalCompleted = 0;
      let totalPending = 0;
      this.gridApi?.forEachNode((node) => {
        if (!this.isLinkCompleted(node.data)) totalPending++;
        else totalCompleted++;
        totalLinks++;
      });
      this.totalLinks = totalLinks;
      this.totalCompleted = totalCompleted;
      this.totalPending = totalPending;
    },

    // Time display helper taken from previous mixin
    displayLocalDateTimeFromEpochMs(params) {
      const epochMs = params.value || params;
      let epoch_ms = epochMs;
      if (epochMs == 'NOW') epoch_ms = Date.now();
      try {
        const eventDate = new Date(epoch_ms);
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        };
        return eventDate.toLocaleTimeString('en-US', options);
      } catch (err) {
        return '-';
      }
    },

    // Filter functions
    agreementTypeValueGetter(params) {
      let { data } = params;
      let docxFile = data.ckeditor_agreement_id || data.superdoc_id;

      if (docxFile) {
        return 'DOCX';
      }

      return 'PDF';
    },

    // Column definitions
    generateColumns() {
      const emailRecipientsGetter = (params) => {
        const emails = new Set();
        params.data.email_recipients?.forEach((recipient) => {
          const isObject = !!recipient.recipientemail;
          emails.add(isObject ? recipient.recipientemail : JSON.parse(recipient)?.recipientemail);
        });
        params.data.submissions?.forEach((submission) => {
          emails.add(submission.signer_email);
        });
        return [...emails];
      };

      const customDateFilterOptions = [
          // custom equality
          {
            displayKey: 'equalByDay',
            displayName: 'Equals',
            predicate: (filterLocalDateAtMidnight, cellValue) => {
              const date = new Date(cellValue);
              const filterDate = new Date(filterLocalDateAtMidnight);

              return (
                date.getFullYear() === filterDate.getFullYear() &&
                date.getMonth() === filterDate.getMonth() &&
                date.getDate() === filterDate.getDate()
              );
            },
          },
          "notEqual",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ]

      const columns = [
        {
          field: 'expander',
          cellRenderer: 'agGroupCellRenderer',
          headerName: '',
          headerClass: 'hidden',
          cellClass: 'expander',
          maxWidth: 30,
          minWidth: 30,
          resizable: false,
          filter: false,
          sortable: false,
          suppressMenu: true,
          suppressSizeToFit: true,
          suppressColumnsToolPanel: true,
          suppressMovable: true,
          pinned: this.harbourStore.isMobileView ? null : 'left',
        },
        {
          field: 'brand',
          headerName: '',
          headerClass: 'hidden',
          minWidth: 54,
          maxWidth: 54,
          pinned: this.harbourStore.isMobileView ? null : 'left',
          cellRenderer: 'BrandRenderer',
          resizable: false,
          filter: false,
          sortable: false,
          suppressMenu: true,
          suppressSizeToFit: true,
          suppressColumnsToolPanel: true,
          suppressMovable: true,
        },
        {
          field: 'title',
          headerName: 'Name',
          editable: (params) => {
            return !params.data.hasPendingApproval;
          },
          minWidth: 140,
          cellStyle: { cursor: 'text' },
          headerClass: 'hrbr-grid-cell-left-align',
          singleClickEdit: true,
          cellEditor: 'TitleEditor',
          cellEditorParams: {
            callback: this.updateLinkTitle,
          },
          valueGetter: (params) => textDeSanitize(params.data.title),
          filterValueGetter: (params) => params.data.title,
          suppressColumnsToolPanel: true,
          pinned: this.harbourStore.isMobileView ? null : 'left',
          suppressMovable: true,
          cellClass: 'limit-to-three-lines hrbr-grid-cell',
          hide: false,
          width: 250,
        },

        // Main columns
        {
          field: 'url',
          headerName: 'Link',
          cellRenderer: 'LinkRenderer',
          minWidth: 120,
          valueGetter: (params) => params.data.url,
          filterValueGetter: (params) => params.data.url,
          filterParams: {
            textFormatter: (value) => this._filterURL(value),
          },
          hide: false,
          width: 165,
        },
        {
          field: 'document',
          headerName: 'Doc',
          cellRenderer: 'ViewAgreementRenderer',
          minWidth: 85,
          maxWidth: 85,
          valueGetter: this.agreementTypeValueGetter,
          hide: false,
          width: 85,
        },
        {
          field: 'workflowsStatus',
          headerName: 'Workflows',
          minWidth: 150,
          cellRenderer: 'WorkflowStatusRenderer',
          cellStyle: { opacity: 1 },
          filterValueGetter: (params) => {
            const workflows = params.data.workflows_status || [];
            const filters = new Set();
            workflows.forEach((workflow) => {
              filters.add(workflow.workflow_name);
            });
            return Array.from(filters);
          },
          hide: false,
          width: 150,
        },
        {
          field: 'completionState',
          headerName: 'Status',
          cellRenderer: 'StateRenderer',
          filter: 'agSetColumnFilter',
          minWidth: 210,
          cellStyle: { opacity: 1 },
          hide: false,
          width: 210,
          filterValueGetter: (params) => {
            const filters = [];
            const workflows = params.data.workflows_status;
            if (workflows) {
              for (let flow of workflows) {
                const isWaitingOnMe = this.workflowsStore.isWaitingOnMe(flow.snapshot);
                if (isWaitingOnMe) filters.push('Review');
                const isApproval = this.workflowsStore.isApprovalWorkflow(flow.snapshot);
                const isWorkflowApproved = this.workflowsStore.isWorkflowApproved(flow.snapshot);
                if (isApproval && !isWorkflowApproved) filters.push('Awaiting approval');
                else if (isApproval && isWorkflowApproved) filters.push('Approved');
              }
            }
            if (this.isLinkCompleted(params.data)) filters.push('Completed');
            else {
              if (params.data.is_sent && !params.data.email_recipients_completed?.length)
                filters.push('Sent');
              else if (!filters.includes('Awaiting approval')) filters.push('Pending');
            }
            return filters;
          },
        },
        {
          field: 'email_recipients',
          headerName: 'Signers',
          valueGetter: emailRecipientsGetter,
          filterValueGetter: emailRecipientsGetter,
          minWidth: 280,
          cellRenderer: 'SignersRenderer',
          cellClass: '',
          hide: false,
          width: 280,
        },
        {
          field: 'total_views',
          headerName: 'Activity',
          minWidth: 90,
          filter: 'agNumberColumnFilter',
          cellRenderer: 'ViewsRenderer',
          hide: true,
          width: 120,
        },
        {
          field: 'folder',
          cellRenderer: 'FolderRenderer',
          headerName: 'Folder',
          minWidth: 120,
          filterValueGetter: (params) => {
            const folderName = this.harbourStore.getFolderName(params.data.folder);
            return folderName;
          },
          hide: false,
          width: 120,
        },
        {
          field: 'authType',
          valueGetter: (params) => {
            const type = params.data?.auth_method?.authmode;
            if (type === 'EMAILS') return 'Email';
            else if (type === 'PASSCODE') return 'Password';
            else if (type === 'PUBLIC') return 'Public';
          },
          headerName: 'Auth type',
          minWidth: 120,
          hide: true,
          width: 120,
        },
        {
          field: 'templateTitle',
          valueGetter: (params) => {
            if (
              params.data.agreement_title &&
              (params.data.is_template || params.data.template_group_id)
            )
              return params.data.agreement_title;
          },
          headerName: 'Template',
          minWidth: 120,
          hide: true,
          width: 120,
        },
        {
          field: 'active',
          headerName: 'Link status',
          cellRenderer: 'ActiveToggleRenderer',
          minWidth: 130,
          filterValueGetter: (params) => {
            const active = params.data.active;
            if (active) return 'Active';
            else return 'Inactive';
          },
          valueGetter: (params) => (params.data.active ? 'ACTIVE' : 'INACTIVE'),
          hide: true,
          width: 130,
        },
        {
          field: 'last_update',
          valueFormatter: ({ value }) => this.harbourStore.formatUnixTimestamp(value),
          valueGetter: (params) => this.harbourStore.convertToUnixMillis(params.data.last_update),
          headerName: 'Last update',
          filterParams: {
            filters: [
              {
                filter: 'agDateColumnFilter',
                filterParams: {
                  filterOptions: customDateFilterOptions,
                }
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  valueFormatter: (params) => this.harbourStore.formatUnixTimestamp(params.value),
                },
              },
            ],
          },
          minWidth: 200,
          wrapText: false,
          hide: true,
          width: 200,
        },
        {
          field: 'created',
          sort: 'desc',
          valueFormatter: ({ value }) => this.harbourStore.formatUnixTimestamp(value),
          valueGetter: (params) => this.harbourStore.convertToUnixMillis(params.data.created),
          filterParams: {
            filters: [
              {
                filter: 'agDateColumnFilter',
                filterParams: {
                  filterOptions: customDateFilterOptions,
                }
              },
              {
                filter: 'agSetColumnFilter',
                filterParams: {
                  valueFormatter: (params) => this.harbourStore.formatUnixTimestamp(params.value),
                },
              },
            ],
          },
          minWidth: 200,
          wrapText: false,
          headerName: 'Create date',
          hide: true,
          width: 200,
        },
        {
          field: 'creationDetails',
          minWidth: 150,
          headerName: 'Creator',
          cellRenderer: 'CreationRenderer',
          valueGetter: (params) => {
            const { creator_name, creator_email } = params.data;
            return [creator_name, creator_email];
          },
          filterValueGetter: (params) => {
            if (params.data.is_api_created)
              return 'via API'

            return params.data.creator_email;
          },
          hide: true,
        },
        {
          field: 'group_ids',
          minWidth: 200,
          headerName: 'Groups',
          cellRenderer: 'GroupsRenderer',
          filter: 'agSetColumnFilter',
          hide: false,
          filterValueGetter: (params) => {
            const groupIds = params.data.group_ids || [];
            const groupNames = groupIds.map((id) => {
              const group = this.settingsStore.listUserGroups.find((i) => i.id === id);
              return group?.name || null;
            });
            return groupNames;
          },
        },
        {
          headerName: 'Automations',
          field: 'automations',
          cellRenderer: 'AutomationsRenderer',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => params.data.automations?.map((a) => a.integration_type) || [],
          resizable: true,
          sortable: false,
          minWidth: 200,
          hide: false,
        },
        {
          field: 'actions',
          headerName: 'Actions',
          cellRenderer: 'DashboardActionsRenderer',
          minWidth: 260,
          maxWidth: 260,
          resizable: false,
          filter: false,
          sortable: false,
          suppressMenu: true,
          hide: false,
          suppressColumnsToolPanel: true,
        },

        // Hidden columns for filters only
        { field: 'creator_email', suppressColumnsToolPanel: true, filter: true, hide: true },
        { field: 'template_id', hide: true, suppressColumnsToolPanel: true },
        {
          field: 'signerEmails',
          getQuickFilterText: (params) => {
            const recipients = params.data.email_recipients || [];
            return recipients.map((recipient) => JSON.parse(recipient)?.recipientemail || '');
          },
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: 'signerNames',
          getQuickFilterText: (params) => {
            const recipients = params.data.email_recipients || [];
            return recipients.map((recipient) => JSON.parse(recipient)?.fullname || '');
          },
          hide: true,
          suppressColumnsToolPanel: true,
        },
        { field: 'auth_method', hide: true, suppressMenu: true, suppressColumnsToolPanel: true },
        { field: 'id', hide: true, suppressMenu: true, suppressColumnsToolPanel: true },
        { field: 'searchMatch', hide: true, suppressMenu: true, suppressColumnsToolPanel: true },
      ];

      this.columnDefs = columns;
    },

    // Generate a saveable user column state array
    generateColumnStateToSave(gridColumnApi, grid, resetToDefaults = false) {
      const columns = gridColumnApi?.getColumnState();

      const defaultColumns = this.initialColsData.get(grid);
      const dataToSave = [];
      columns?.map((col) => {
        const stateToSave = {};
        const initialData = defaultColumns?.find((colData) => colData.colId === col.colId);

        this.columnKeysToSave?.forEach((key) => {
          if (resetToDefaults && initialData) {
            stateToSave[key] = initialData[key] !== undefined ? initialData[key] : col[key];
          } else stateToSave[key] = col[key];
        });
        dataToSave.push(stateToSave);
      });
      return dataToSave;
    },

    // Restore user-saved column state
    restoreUserColumnState(
      grid = 'dashboard',
      gridApi = this.gridApi,
      gridColumnApi = this.gridColumnApi,
    ) {
      const gridName = `grid-columns-${grid}`;
      // left fixed columns
      const fixedColNames = ['expander', 'brand'];

      // Ignore folders since that is global view now
      if (gridName === 'grid-columns-folders') return;

      if (gridName in this.harbourStore.savedSettings) {
        let state = this.harbourStore.savedSettings[gridName];

        const fixedCols = state
          .filter((col) => fixedColNames.includes(col.colId))
          .sort((a, b) => fixedColNames.indexOf(a.colId) - fixedColNames.indexOf(b.colId));
        const other = state.filter((col) => !fixedColNames.includes(col.colId));
        state = [...fixedCols, ...other];

        gridColumnApi?.applyColumnState({ state: state || [], applyOrder: true });
      }
    },

    resetGridApi() {
      this.gridApi = null;
      this.gridColumnApi = null;
      this.gridAssets = null;
    },

    // Save user's column layout to user preferences on the server
    async saveColumnLayout(gridColumnAPi, grid = 'dashboard', resetToDefault = false) {
      const state = this.generateColumnStateToSave(gridColumnAPi, grid, resetToDefault);
      const gridName = `grid-columns-${grid}`;

      this.harbourStore.savedSettings[gridName] = state || [];
      const options = {};
      options[gridName] = state;

      const result = await this.harbourStore.updateUserSettings(options);
      if (result?.status === 200) {
        Toast.open({
          duration: 2500,
          message: 'Column layout saved successfully',
          position: 'is-top',
          type: 'is-success',
        });
      } else {
        Toast.open({
          duration: 2500,
          message: 'Error while saving column layout. Try again in a moment.',
          position: 'is-top',
          type: 'is-warning',
        });
      }
      console.debug('Column layout saved successfully', result);
    },

    // Filter: By state (ALL, COMPLETE, PENDING)
    filterByState() {
      const filterInstance = this.gridApi?.getFilterInstance('completionState');
      if (!filterInstance) return;

      let filter = {
        type: 'set',
        values: [this.mylinksCompleteState],
      };
      this.mylinksCompleteState === 'ALL' && (filter = null);
      filterInstance.setModel(filter);
      this.gridApi.onFilterChanged();
    },

    setInitialView() {
      if (this.harbourStore.isAdmin) {
        this.adminState === 'adminview' ? this.setAdminView() : this.setDefaultView();
      } else {
        this.setDefaultView();
      }
    },

    resetViewFilters() {
      const filterNames = ['creator_email', 'group_ids'];
      filterNames.forEach((filterName) => {
        const filterInstance = this.gridApi?.getFilterInstance(filterName);
        if (!filterInstance) return;
        filterInstance.setModel(null);
      });
    },

    applyDateFilter(val) {
      const filterComponent = this.gridApi?.getFilterInstance('created');
      filterComponent.setModel({
        filterType: 'multi',
        filterModels: [
          {
            dateFrom: val[0] ? dateFormat(val[0], 'yyyy-mm-dd HH:MM:ss') : '',
            dateTo: val[1] ? dateFormat(val[1], 'yyyy-mm-dd HH:MM:ss') : '',
            filterType: 'date',
            type: 'inRange',
          },
        ],
      });

      this.gridApi.onFilterChanged();
    },

    setDashboardFilter(groupId = null) {
      if (this.adminState === 'adminview') this.setAdminView();
      else if (this.adminState === 'groupadminview') this.setGroupAdminView(groupId);
      else this.setDefaultView();
    },

    setGroupAdminView(groupId = null) {
      this.resetViewFilters();

      const filterInstance = this.gridApi?.getFilterInstance('group_ids');
      if (!filterInstance) return;

      this.adminState = 'groupadminview';
      this.gridColumnApi?.setColumnVisible('creationDetails', true);

      const filter = {
        type: 'set',
        values: [groupId],
      };

      filterInstance.setModel(filter);
      this.gridApi.onFilterChanged();
    },

    setAdminView() {
      this.resetViewFilters();

      const filterInstance = this.gridApi?.getFilterInstance('creator_email');
      if (!filterInstance) return;
      this.adminState = 'adminview';
      this.gridColumnApi?.setColumnVisible('creationDetails', true);
      filterInstance.setModel(null);
      this.gridApi.onFilterChanged();
    },

    setDefaultView() {
      this.resetViewFilters();

      const filterInstance = this.gridApi?.getFilterInstance('creator_email');
      if (!filterInstance) return;
      this.adminState = 'defaultview';
      const userEmail = this.harbourStore.contextDict?.systememail;
      if (!userEmail) return;

      let filter = {
        type: 'set',
        values: [userEmail],
      };

      // Status of whether or not the user has the 'creator' column saved in their column settings
      if (this.harbourStore.savedSettings['dashboard']) {
        const hasCreatorColumnVisible = !!this.harbourStore.savedSettings[
          'grid-columns-dashboard'
        ].find((column) => column.colId === 'creationDetails' && !column.hide);

        this.gridColumnApi?.setColumnVisible('creationDetails', hasCreatorColumnVisible);
      }

      filterInstance?.setModel(filter);
      this.gridApi?.onFilterChanged();
    },

    _filterURL(text) {
      try {
        const url = new URL(text);
        return url.pathname.split('/').pop();
      } catch (e) {
        return text;
      }
    },

    resetFilterById() {
      this.isSearchingServer = false;
      const filterInstance = this.gridApi?.getFilterInstance('id');
      if (!filterInstance) return;
      filterInstance.setModel(null);
      this.gridApi?.onFilterChanged();
    },

    async filterByInputValues() {
      if (this.isSearchingServer || !this.textFilterValue) return;
      this.lastSearch = this.textFilterValue;
      this.isSearchingServer = true;
      this.resetSearchMatches();
      const options = {
        requesttype: 'assetlibrary-getmylinks',
        linkstatefiltervalue: 'ALL',
        textfiltervalue: this.textFilterValue,
        emailfiltervalue: null,
        displayidfiltervalue: null,
        agreementidfiltervalue: null,
        sortvalueorder: 'UPDATED-DESC',
        isadminview: this.harbourStore.isAdmin && this.adminState === 'adminview',
        requestresultsoffset: 0,
      };

      const result = await Vue.prototype.$harbourData.post(`/core/inputsearch/`, options);

      const gridApi = this.gridApi;
      if (!gridApi) return;

      result.data?.forEach((match) => {
        const link = gridApi.getRowNode(match)?.data;
        if (!link) return;
        this.inputSearchMatches.add(link.id);
        link.searchMatch = this.textFilterValue;
      });

      this.gridApi?.onFilterChanged();
      this.isSearchingServer = false;
    },

    async resetSearchMatches() {
      if (this.inputSearchMatches.size === 0) return;
      this.inputSearchMatches = new Set();

      const gridApi = this.gridApi;
      if (!gridApi) return;

      gridApi.forEachNode((node) => {
        node.data.searchMatch = '';
      });
    },

    // Filter: Apply quick filter to dashboard main grid
    updateTextFilters(currentText) {
      this.lastSearch = null;
      if (!currentText) {
        this.textFilterValue = null;
        this.gridApi?.setQuickFilter(null);
        return;
      }

      this.textFilterValue = currentText;
      if (!this.textFilterValue) return;

      clearTimeout(this.quickFilterTimeout);
      this.quickFilterTimeout = setTimeout(() => {
        let filterText = this.textFilterValue;

        if (!this.textFilterValue) {
          return;
        }
        // If this is a URL, attempt to extract the ID to match the URL column by display id regardless of url
        if (
          this.textFilterValue.startsWith('http://') ||
          this.textFilterValue.startsWith('https://')
        ) {
          filterText = this._filterURL(this.textFilterValue);
        }
        this.gridApi?.setQuickFilter(filterText);
      }, 50);
    },

    // Updating filter for awaiting my review grid
    updateAwaitingMyReviewFilter(currentText) {
      if (!currentText) {
        this.textFilterValue = null;
        this.gridApi?.setQuickFilter(null);
        return;
      }

      this.textFilterValue = currentText;
      clearTimeout(this.quickFilterTimeout);
      this.quickFilterTimeout = setTimeout(() => {
        let filterText = this.textFilterValue;
        this.gridApi?.setQuickFilter(filterText);
      }, 200);
    },

    // Filter: By template ID
    filterByTemplate(id) {
      const filterInstance = this.gridApi?.getFilterInstance('templateId');
      if (!filterInstance) return;

      let filter = {
        type: 'set',
        values: [id],
      };
      if (id.toLowerCase() === 'all') {
        filter = null;
        this.myLinksFilterSelectedAgreementId = null;
      }
      filterInstance.setModel(filter);
      this.gridApi.onFilterChanged();
    },

    // Master/detail programmatic toggle
    expandDetails(id, openToPane = null) {
      const node = this.gridApi.getRowNode(id);
      if (node) {
        if (openToPane === 'live') node.data.live = true;
        node.setExpanded(!node.expanded);
      }
    },

    // Helpers - this was copied from the previous dashboard unchanged
    // should refactor for clarity
    // This method is not used in the code (probably not needed).
    copyToClipboard(linkDisplayId, linkSubdomain) {
      let shareLinkValue = '';
      let domain = 'hrbr.co/';
      // let subdomain = '';
      // if (linkSubdomain) {
      //   subdomain = `${linkSubdomain}.`;
      // }

      const locationHref = window.location.href;
      const protocol = 'https';

      if (locationHref.includes('localhost')) {
        const localBase = 'http://localhost:9090';
        shareLinkValue = `${localBase}/agree/${linkDisplayId}`;
      } else if (locationHref.includes('127.0.0.1')) {
        const loc = window.location;
        const localBase = `${loc.protocol}//${loc.hostname}${loc.port ? `:${loc.port}` : ''}`;
        shareLinkValue = `${localBase}/agree/${linkDisplayId}`;
      } else if (locationHref.includes('-staging.')) {
        shareLinkValue = `https://new-staging.myharbourshare.com/agree/${linkDisplayId}`;
      } else if (locationHref.includes('harbour-prod-webapp.uc.r.appspot.com')) {
        const [localBase] = locationHref.split('appspot.com');
        shareLinkValue = `${localBase}appspot.com/agree/${linkDisplayId}`;
      } else {
        // shareLinkValue = `${protocol}://${subdomain}${domain}${linkDisplayId}`;
        shareLinkValue = `${protocol}://${domain}${linkDisplayId}`;
      }

      navigator.clipboard.writeText(shareLinkValue).then(
        () => {
          Toast.open({
            duration: 2500,
            message: 'Success - agreement url copied to clipboard',
            position: 'is-top',
            type: 'is-success',
          });
        },
        () => {
          Toast.open({
            message: 'Sorry, failed to copy agreement url to clipboard',
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
        },
      );
    },

    // Update the link title on server
    async updateLinkTitle(originalValue, newTitle, link_id) {
      const key = 'title';
      const options = {
        update_request: {
          value: newTitle,
          display_id: link_id,
        },
      };
      const result = await Vue.prototype.$harbourData.put('/core/myLinks/update/title', options);
      return this.verifyResult(originalValue, result, key, link_id);
    },

    // Update the link parent folder on server
    async updateLinkFolder(folder, display_id) {
      // Update the link folder on the server
      const value = folder.id;
      const options = {
        update_request: {
          value,
          display_id,
        },
      };
      const result = await Vue.prototype.$harbourData.put('/core/myLinks/update/folder', options);
      return result;
    },

    // Verify the result of a server update or rollback if necessary
    verifyResult(originalValue, result, key, itemId) {
      // If result is not 200, revert the change on the item
      if (result.status !== 200) {
        const item = this.gridApi.getRowNode(itemId);
        item[key] = originalValue;
        return false;
      }
      return true;
    },

    async transferLinkOwnership(linkObject) {
      Modal.open({
        component: TransferOwnership,
        props: {
          linkObject,
        },
      });
    },
    // Delete a link from the dashboard
    async deleteLink(linkObject) {
      // Confirm deletion
      const confirmation = `Are you certain you want to delete this link ("${linkObject.title}")?`;
      if (!confirm(confirmation)) return false;

      // Send request
      Vue.prototype.$harbourData.post('data?agreebuilder-deletemylink', {
        requesttype: 'agreebuilder-deletemylink',
        agreedisplayid: linkObject.id,
      });

      // Remove from from display right away
      this.gridApi.applyTransaction({
        remove: [linkObject],
      });
    },

    linkHasPendingApproval(params) {
      const isLinkComplete = this.isLinkCompleted(params.data);
      if (params.data?.is_locked) return true;
      if (isLinkComplete) return null;

      const hasPendingApproval = params.data?.workflows_status?.some((workflow) => {
        const snapshot = workflow.snapshot;
        if (!snapshot || !snapshot.blocks) return false;
        const isApproved = this.workflowsStore.isWorkflowApproved(snapshot);
        const isCompletedWorkflow = this.workflowsStore.isWorkflowCompleted(workflow);
        const isFailedWorkflow = this.workflowsStore.isWorkflowFailed(workflow);
        const isPendingApproval = workflow.pending_approval_ids?.length > 0;

        if (isFailedWorkflow && !isApproved) {
          return true;
        }

        return (
          isPendingApproval &&
          !isApproved &&
          !isCompletedWorkflow
        );
      });
      if (hasPendingApproval) return true;
      return false;
    },

    getDomainFromEmail(email) {
      const regex = /@(.+)/;
      const matches = regex.exec(email);
      if (matches && matches.length > 1) {
        return matches[1];
      }
      return null;
    },

    openPresentationPdf(linkObject, src, parent) {
      // Open PDF preview
      const isCompleted = this.isLinkCompleted(linkObject);
      const completedSigners = linkObject.email_recipients_completed?.length;

      const isEditable = !isCompleted && !completedSigners;
      Modal.open({
        parent,
        component: PdfPreview,
        canCancel: true,
        hasModalCard: false,
        fullScreen: true,
        props: {
          tgturl: src,
          isEditable: isEditable,
          linkDisplayIdToEdit: linkObject.id,
          parent: this,
        },
        events: {
          close: () => {
            console.debug('Closing PDF preview');
          },
        },
      });
    },

    openCkPreview(ckeditorHtmlString) {
      // Open CK preview
      Vue.prototype.$openCkeditorHtmlViewer({
        props: {
          htmlData: ckeditorHtmlString,
          source: 'library',
        },
      });
    },

    dashboardGridAutoSize() {
      setTimeout(() => {
        const gridReference = this.gridReference;
        const gridApi = this.gridApi;
        const gridColumnApi = this.gridColumnApi;
        this.harbourStore.autosizeGridByReference(gridReference, gridApi, gridColumnApi);
      }, 0);
    },

    autoSizeDashboardByPercent() {
      // Resize the dashboard according to column width percentages to maintain aspect ratio based on
      // User settings, if any
      const gridElement = this.gridReference?.$el;
      const skipColumns = ['expander', 'brand', 'document', 'actions'];
      const visibleColumns = this.gridColumnApi?.getAllDisplayedColumns();

      if (!visibleColumns || !gridElement) return;

      // Get the widths
      const visibleWidth = visibleColumns.reduce((acc, column) => acc + column.actualWidth, 0);
      const staticColumns = visibleColumns.filter((column) => skipColumns.includes(column.colId));
      const staticWidth = staticColumns.reduce((acc, column) => acc + column.actualWidth, 0);
      const availableWidth = gridElement.getBoundingClientRect().width - 20;

      // Get a list of resizeable columns
      const columnsToResize = visibleColumns.filter(
        (column) => !skipColumns.includes(column.colId),
      );
      const newSizes = [];
      columnsToResize.forEach((column) => {
        const widthPercent = column.actualWidth / (visibleWidth - staticWidth);
        const newWidth = (availableWidth - staticWidth) * widthPercent;
        const newColWidth = {
          key: column,
          newWidth,
        };
        newSizes.push(newColWidth);
      });
      this.gridColumnApi.setColumnWidths(newSizes);
    },

    async getCkeditorActiveAgreementId(file_id) {
      try {
        const respData = await this.ckeditorStore.loadCkeditorMetadata(file_id);
        const { activeagreementid } = respData.refids;
        return activeagreementid;
      } catch (err) {
        Toast.open({
          duration: 2500,
          message: 'Error while opening this document. Try again in a moment or contact support.',
          position: 'is-top',
          type: 'is-danger',
        });
        return null;
      }
    },

    async removeDraft({ id, name }) {
      //Confirm?
      const confirmation = confirm(
        'Are you sure you want to fully delete the draft ("' + name + '")?',
      );
      if (!confirmation) return false;

      //Remove
      const sourceItems = this.harbourStore.myDrafts;
      const itemIndex = sourceItems.findIndex((item) => item.id === id);
      sourceItems.splice(itemIndex, 1);
      Toast.open({
        duration: 2500,
        message: 'Success - draft removed',
        position: 'is-top',
        type: 'is-success',
      });

      //Flag for server removal
      await this.libraryStore.filesystemStartAssetDeletion(id, name);
    },

    deobfuscateArrayBuffer(arrayBuffer) {
      // Used for retrieving data from the caches API

      const dataView = new DataView(arrayBuffer);
      const uint8Array = new Uint8Array(arrayBuffer);
      const key = 0xab; // XOR encryption key

      // Apply XOR decryption to each byte of the data
      for (let i = 0; i < dataView.byteLength; i++) {
        uint8Array[i] ^= key;
      }

      return uint8Array.buffer;
    },

    setLoadingView(val) {
      this.isLoadingView = val;
    },

    async openSuperdocCompletedPdfPreview(data, linkObject, parentComponent = null) {
      this.setLoadingView(true);

      const link = linkObject();
      if (!link) return;

      const pdfUrl = `/asset?cb=${Date.now()}&id=${data.id}&agreelinkid=${link.id}`;
      const pdfFile = await fetch(pdfUrl);
      const pdfBlob = await pdfFile.blob();
      const pdfFileBlob = new File([pdfBlob], data.name, { type: 'application/pdf' });

      Vue.prototype.$openSuperdocViewerModal({
        linktitle: data.name,
        id: data.id,
        source: pdfFileBlob,
        headerCtas: [
          {
            id: 'download',
            label: 'Download',
            icon: 'file-arrow-down',
            type: 'secondary',
          },
        ],
        parent: parentComponent,
      });

      this.setLoadingView(false);
    }
  },
});
