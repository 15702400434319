<script>
import { storeToRefs } from 'pinia';
import { getBlobFromUrl, readFileAsDataUrl } from '@/utils/helpers/functions';
import uploadcare from 'uploadcare-widget/uploadcare.lang.en';
import { useBillingStore } from '@/pages/Settings/stores/billing-store';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useCkeditorStore } from '../Ckeditor/stores/ckeditor-store';
import { useAgreementEditorStore } from './stores/agreement-editor-store';
import harbourBoatIconSvg from '@/assets/icons/harbour-boat-primary.svg?raw';
import HrbrAgreementEditorRecentTemplate from './HrbrAgreementEditorRecentTemplate.vue';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import { useSuperdocStore } from '@/stores/superdoc-store.js';

export default {
  name: 'HrbrAgreementEditorUploader',

  components: {
    HrbrAgreementEditorRecentTemplate,
  },

  props: {
    activeTemplateId: {
      type: String,
    },
    agreementData: {
      type: Object,
      default: null,
    },
    activeFileVersionDisplayId: {
      type: String,
    },
    topParentFileVersionDisplayId: {
      type: String,
    },
    isUploaderStepFirstLoad: {
      type: Boolean,
      required: true,
    },
    creationMode: {
      type: String,
      required: true,
    },
    addonKey: {
      type: String,
      default: null,
    },
    templateBase64: {
      type: String,
      default: null,
    },
    isConvertToPdfInit: {
      type: Boolean,
      required: false,
    },
    isFileRemove: {
      type: Boolean,
    },
    fileToUpload: {
      type: File,
    },

    // ckeditor props
    ckeditorFileId: {
      type: String,
      default: null,
    },
    isBlankCkDocument: {
      type: Boolean,
      default: false,
    },
    isCkDocumentFromFile: {
      type: Boolean,
      default: false,
    },

    // pdf drafts
    isPdfDocumentFromFile: {
      type: Boolean,
      default: false,
    },

    // SuperDoc
    isSuperdoc: {
      type: Boolean,
    },

    templateGroupId: {
      type: String,
    },
  },

  setup() {
    const harbourStore = useHarbourStore();
    const templatesStore = useTemplatesStore();
    const billingStore = useBillingStore();
    const ckeditorStore = useCkeditorStore();
    const agreementEditorStore = useAgreementEditorStore();
    const magicAnnotationsStore = useMagicAnnotationsStore();
    const superdocStore = useSuperdocStore();
    const { uploadedDocData, isOnlySignerModalOpened, uploadedFile, isOnlySigner, preloadedFileId } = storeToRefs(magicAnnotationsStore);

    return {
      harbourStore,
      templatesStore,
      billingStore,
      ckeditorStore,
      agreementEditorStore,
      superdocStore,
      isOnlySigner,
      uploadedDocData,
      uploadedFile,
      isOnlySignerModalOpened,
      preloadedFileId,
    };
  },

  data() {
    return {
      widget: null,
      hasErrors: false,
      isLoading: false,
      isTemplateCreationMode: false,
      isConvertingFileToDocx: false,
      isConvertingFileToPdf: false,
      isStoringFileVersion: false,
      isLoadingData: false,
      originalFileType: null,
      originalBase64: null,
      maxFileUploadSizeMB: 24,
      minCompressionSizeMB: 10,
      isConvertToPdf: this.isConvertToPdfInit,
      isUploadingFile: false,
      email: '',
      harbourBoatIcon: harbourBoatIconSvg,
    };
  },

  computed: {
    showUploadcareWidget() {
      return !this.isLoading && !this.isLoadingData;
    },

    showLoader() {
      return this.isLoading || this.isLoadingData;
    },

    widgetOptions() {
      return {
        tabs: 'file gdrive dropbox onedrive box url',
        doNotStore: 0,
      };
    },

    acceptedFileTypes() {
      return [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
    },
  },

  watch: {
    isFileRemove() {
      this.onFileRemoveClick();
    },
    isOnlySigner: {
      handler(val) {
        if (val) this.isConvertToPdf = true;
      },
      immediate: true
    }
  },

  methods: {
    onBlankDocumentClick() {
      if (this.superdocStore.hasSuperDocBeta) {
        this.superdocStore.openSuperdocFromUpload({
          file: null,
          isBlankDocument: true,
          mode: this.isTemplateCreationMode ? 'template' : 'agreement',
          templateGroupId: this.templateGroupId,
          parent: this,
        });

        this.$emit('modal-close', {});
        return;
      }

      this.blankDocumentHandler();
    },

    async blankDocumentHandler() {
      this.hideModal();

      let url = 'https://storage.googleapis.com/public_statichosting/word_documents/blank.docx';
      let name = 'Untitled.docx';
      let mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      let sourceFile = await this.superdocStore.createFileObject(url, name, mimeType);

      const fileInfo = {
        name,
        mimeType,
        originalUrl: url,
        size: 11839,
        isBlank: true,
        sourceFile,
      };

      if(this.isConvertToPdf) {
       this.$emit('upload-file', {
           file: {},
           originalFileType: 'pdf',
           isNextStep: true,
       });
      } else if (this.isSuperdoc) {
        this.$emit('upload-file', {
          file: fileInfo,
          originalFileType: 'superdoc-word',
          isNextStep: false,
          isBlank: true,
        });
      } else {
        this.$emit('upload-file', {
          file: fileInfo,
          originalFileType: 'word',
          isNextStep: false,
          isBlank: true,
        });
      }

      await this.onFileUpload(fileInfo);
    },

    fromFileDocumentHandler(file, isPdf = false) {
      if (!file) return;

      this.hideModal(2600);

      const upload = uploadcare.fileFrom('object', file);
      const onDone = async (fileInfo) => {
        const fileInfoToEmit = {
          name: fileInfo.name,
          mimeType: fileInfo.mimeType,
          originalUrl: fileInfo.originalUrl,
          size: fileInfo.size,
          sourceFile: file,
        };
        this.$emit('upload-file', {
          file: fileInfoToEmit,
          originalFileType: isPdf ? 'pdf' : 'word',
          isNextStep: isPdf,
          isCkDocFromFile: !isPdf,
        });
        await this.onFileUpload(fileInfo);
      };
      upload.done(onDone).fail(console.error);
    },

    hideModal(timeout = 2000) {
      const modalElem = document.querySelector('.hrbr-agreement-editor-modal');
      const hiddenClass = 'hrbr-agreement-editor-modal--hidden';
      if (modalElem) {
        modalElem.classList.add(hiddenClass);
        setTimeout(() => modalElem.classList.remove(hiddenClass), timeout);
      }
    },

    updateCreationMode() {
      let creationMode = this.isTemplateCreationMode ? 'template' : 'agreement';
      this.$emit('update-creation-mode', creationMode);
    },

    updateFileVersionDisplayId(fileVersionDisplayId) {
      this.$emit('file-version-display-id-change', fileVersionDisplayId);
    },

    updateTopParentFileVersionDisplayId(fileVersionDisplayId) {
      this.$emit('top-parent-file-version-display-id-change', fileVersionDisplayId);
    },

    initUploadcareWidget() {
      this.hasErrors = false;
      this.openUploadcarePanel();
      this.attachUploadcareHandlers();
      this.replaceUploadcareIcons();
      this.isUploadingFile = false;
    },

    openUploadcarePanel() {
      const { widgetElement } = this.$refs;

      if (!widgetElement) return;

      const options = {
        ...this.widgetOptions,
        validators: [
          this.validateMimeType(this.acceptedFileTypes),
          this.validateMinFileSize(5),
          this.validateFileNameLength(200),
        ],
      };

      this.widget = uploadcare.openPanel('.hrbr-agreement-editor-uploader__widget', null, options);
    },

    attachUploadcareHandlers() {
      if (!this.widget) return;

      // widget closed and a file selected
      this.widget.done(async (filePromise) => {
        const sourceFileInfo = await this.getSourceFileInfo(filePromise);
        const hasSourceFileInfo = !!sourceFileInfo;

        if (!this.hasErrors) {
          this.isUploadingFile = true;
          if (hasSourceFileInfo && !this.isOnlySigner) {
            const mimeType = sourceFileInfo.type.split(';')[0];
            const isPdfFileUpload = mimeType === 'application/pdf' || this.isConvertToPdf;

            let originalFileType = null;
            if (isPdfFileUpload) {
              originalFileType = 'pdf'
            } else {
              originalFileType = this.isSuperdoc ? 'superdoc-word' : 'word';
            }

            let superdocBetaDocx = this.superdocStore.hasSuperDocBeta && !isPdfFileUpload;

            if (!superdocBetaDocx) {
              this.initialFileUploadEvent(originalFileType);
            }
          }
        }

        filePromise
          .done((fileInfo) => {
            const isWidgetUploading = true;
            this.onFileUpload(fileInfo, isWidgetUploading, hasSourceFileInfo);
          })
          .fail(this.onFileUploadError);
      });

      this.widget.fail((error) => {
        this.hasErrors = true;
        this.$emit('error');

        console.error(error);
      });
    },

    getSourceFileInfo(filePromise) {
      return new Promise((resolve, reject) => {
        filePromise.progress((uploadInfo) => {
          const fileInfo = uploadInfo.incompleteFileInfo;
          const file = fileInfo.sourceInfo.file || null;
          resolve(file);
        });
        filePromise.fail(() => {
          reject(null);
        });
      });
    },

    initialFileUploadEvent(originalFileType) {
      const isWordDoc = originalFileType === 'word';
      const eventData = {
        file: {},
        originalFileType: originalFileType,
        isNextStep: false,
        isNextCkStep: false,
      };
      if (isWordDoc) eventData.isNextCkStep = true;
      else eventData.isNextStep = true;
      this.$emit('upload-file', eventData);
    },

    async onFileUpload(fileInfo, isWidgetUploading = false, hasSourceFileInfo = false) {
      const fileName = fileInfo.name;
      const mimeType = fileInfo.mimeType.split(';')[0]; // remove charset
      const isPdfFileUpload = mimeType === 'application/pdf' || this.isConvertToPdf;

      let originalFileType = null;
      if (isPdfFileUpload) {
        originalFileType = 'pdf'
      } else {
        originalFileType = this.isSuperdoc ? 'superdoc-word' : 'word';
      }

      this.originalFileType = originalFileType;

      let superdocBetaDocx = this.superdocStore.hasSuperDocBeta
        && isWidgetUploading
        && !isPdfFileUpload
        && !this.isOnlySigner;

      if (superdocBetaDocx) {
        let mimeType = fileInfo.mimeType.split(';')[0]; // remove charset
        let file = await this.superdocStore.createFileObject(
          fileInfo.originalUrl,
          fileInfo.name,
          mimeType,
        );

        this.superdocStore.openSuperdocFromUpload({
          file,
          isBlankDocument: false,
          mode: this.isTemplateCreationMode ? 'template' : 'agreement',
          templateGroupId: this.templateGroupId,
          parent: this,
        });

        this.$emit('modal-close', {});
        return;
      }

      if (isWidgetUploading && !hasSourceFileInfo && !this.isOnlySigner) {
        this.initialFileUploadEvent(this.originalFileType);
      }

      try {
        if (isPdfFileUpload) {
          if (mimeType !== 'application/pdf' && this.isConvertToPdf) {
            fileInfo = await this.convertFileToPdf(fileInfo);
          }

          this.handleUploaderNewFile(fileInfo);
        } else {
          const docFile =
            mimeType === 'application/msword' || fileName?.toLowerCase().endsWith('.doc');
          const fileExtension = docFile ? 'doc' : 'docx';

          let blobUrl = fileInfo.originalUrl;
          const docxFileBlob = await getBlobFromUrl(blobUrl);

          if (this.isSuperdoc) {
            this.handleNewSuperdocDocument({
              docxFileBlob,
              fileExtension,
              fileInfo,
            });
          } else {
            this.handleNewCkeditorDocument(docxFileBlob, fileExtension, fileInfo);
          }
        }
      } catch (error) {
        this.onFileUploadError(error);
      }
      this.initUploadcareWidget();
    },

    onFileUploadError(error) {
      this.hasErrors = true;
      this.isLoading = false;
      this.onFileRemoveClick();
      this.$emit('error');
      this.$emit('update-current-step', 0);
    },

    onFileRemoveClick() {
      this.$emit('remove-file');
      this.initUploadcareWidget();
    },

    onErrorButtonClick() {
      this.hasErrors = false;
      this.isLoading = false;

      setTimeout(() => {
        this.initUploadcareWidget();
      }, 250);
    },

    async handleUploaderNewFile(fileInfo) {
      if (!fileInfo) return null;
      const fileName = fileInfo.name ? fileInfo.name : 'agreement.pdf';

      const newFile = {
        fileId: `pdf-${fileName.replace(/\W/g, '')}-${Math.random()
          .toString(36)
          .slice(2)}-${Date.now()}`,
        fileDocumentName: fileName.match(/([^/]*?)(?:\.[^.]*)?$/)[1],
        fileSizeBytes: fileInfo.size,
        fileInputMethod: 'UPLOAD',
        fileName: fileName,
      };
      const fileVersionDisplayId = this.agreementEditorStore.generateFileVersionDisplayId();
      this.updateFileVersionDisplayId(fileVersionDisplayId);
      if (!this.isOnlySigner) {
        this.$emit('upload-file', {
          file: newFile,
          originalFileType: this.originalFileType,
          isNextStep: false,
        });
      }

      //Run starter validations
      this.validateFile(fileInfo, ['MIMETYPE', 'MINSIZE', 'FILENAMELENGTH', 'MAXSIZE']);

      this.validateFileExtension('pdf')({ name: fileName });

      let fileBase64 = null;

      if (fileInfo.base64) {
        fileBase64 = fileInfo.base64;
      } else if (this.agreementData?.documentbase64) {
        fileBase64 = this.agreementData.documentbase64;
      } else {
        const blob = await getBlobFromUrl(fileInfo.originalUrl);
        fileBase64 = await readFileAsDataUrl(blob);
      }
      const fileBytesToMegaBytes = (fileInfo.size / (1024 * 1024)).toFixed(2);

      //Attempt to compress PDF if larger than max size
      if (
        fileInfo.mimeType == 'application/pdf' &&
        fileInfo.size > this.minCompressionSizeMB * 1024 * 1024
      ) {
        const compressToast = this.$buefy.toast.open({
          message: `Attempting auto-compression on large PDF (${fileBytesToMegaBytes} MB) for processing, may take a bit...`,
          type: 'is-info',
          position: 'is-top',
          duration: 80000,
        });
        fileBase64 = await this.compressPdf(fileName, fileBase64);
        compressToast.close();
        if (fileBase64 != null) {
          fileBase64 = 'data:application/pdf;base64,' + fileBase64.split(';base64,').pop();
          fileInfo.base64 = fileBase64;
        }
      }

      //Run additional final maxsize validation
      this.validateFile(fileInfo, ['MAXSIZE']);

      newFile.fileBase64 = fileBase64;
      this.isLoading = false;
      if (this.isOnlySigner) {
        this.$emit('modal-close', {});

        this.isOnlySignerModalOpened = true;
        this.uploadedDocData = newFile;

        const blob = await getBlobFromUrl(fileBase64);
        const docFile = new File([blob], fileInfo.name, { type: fileInfo.mimeType });
        this.uploadedFile = fileInfo.sourceInfo?.file ? fileInfo.sourceInfo?.file : docFile;

      } else {
        this.$emit('upload-file', {
          file: newFile,
          originalFileType: this.originalFileType,
          isNextStep: false,
        });
      }

      this.storeCurrentFileVersion({
        fileVersionDisplayId: fileVersionDisplayId,
        file: fileInfo,
        fileBase64,
      });
      return newFile;
    },

    async handleNewSuperdocDocument({
      docxFileBlob,
      fileExtension,
      fileInfo,
    } = {}) {
      const fileName = fileInfo.name || 'agreement.docx';
      const newFile = {
        fileId: `file-${fileName.replace(/\W/g, '')}-${Math.random()
          .toString(36)
          .slice(2)}-${Date.now()}`,
        fileDocumentName: fileName.match(/([^/]*?)(?:\.[^.]*)?$/)[1],
        fileSizeBytes: fileInfo.size,
        fileInputMethod: 'UPLOAD',
        fileName,
        sourceFile: fileInfo.sourceInfo?.file || fileInfo.sourceFile,
      };

      const fileVersionDisplayId = this.agreementEditorStore.generateFileVersionDisplayId();
      this.updateFileVersionDisplayId(fileVersionDisplayId);

      this.$emit('upload-file', {
        file: newFile,
        originalFileType: this.originalFileType,
        isNextStep: false,
      });
    },

    /**
     * @ckeditor method
     */
    async handleNewCkeditorDocument(docxFileBlob, fileExtension, fileInfo) {
      if (!docxFileBlob || !fileInfo) return null;

      const fileName = fileInfo.name || 'agreement.docx';
      this.$emit('initial-ckeditor-upload', true);
      const convertFrom = fileExtension;

      if (convertFrom === 'doc') {
        this.isConvertingFileToDocx = true;
      }
      const newFile = {
        fileId: `file-${fileName.replace(/\W/g, '')}-${Math.random()
          .toString(36)
          .slice(2)}-${Date.now()}`,
        fileDocumentName: fileName.match(/([^/]*?)(?:\.[^.]*)?$/)[1],
        fileSizeBytes: fileInfo.size,
        fileInputMethod: 'UPLOAD',
        fileName,
        sourceFile: fileInfo.sourceInfo?.file || fileInfo.sourceFile,
      };
      const fileVersionDisplayId = this.agreementEditorStore.generateFileVersionDisplayId();
      this.updateFileVersionDisplayId(fileVersionDisplayId);
      this.isLoading = false;
      this.$emit('upload-file', {
        file: newFile,
        originalFileType: this.originalFileType,
        isNextStep: false,
      });
      let docxFinal = null;
      if (this.isConvertingFileToDocx) {
        console.log('Converting to docx');
        // use converter from docx to doc
        const converted = await this.convertFileToDocx(fileInfo);
        docxFinal = converted.file;
      } else {
        docxFinal = docxFileBlob;
      }
      this.isConvertingFileToDocx = false;
      let fileBase64 = await readFileAsDataUrl(docxFinal);

      // get html from docx
      const data = await this.ckeditorStore.importFromWord({ fileName, fileBase64 });

      // cause an infinite loop when exit AE before CK editor loaded
      if (!data) return;

      // decode base64 html
      let htmlString = data.html;

      // html post-processing
      htmlString = await this.ckeditorStore.toUploadHtml(htmlString);

      // encode base64 html
      data.base64data = btoa(encodeURIComponent(htmlString));

      data.base64data = data.base64data.split(';base64,').pop();
      const newBase64 = `data:text/html;base64,${data.base64data}`;
      if (this.creationMode === 'template') {
        this.$emit('update-original-base-64', newBase64);
      }

      // Set metadata if available
      let suggestions = [];
      let comments = [];
      if (data.suggestions && data.suggestions.length > 0) {
        suggestions = data.suggestions;
      }
      if (data.comment_threads && data.comment_threads.length > 0) {
        comments = data.comment_threads;
      }
      newFile.fileBase64 = newBase64;
      newFile.htmlData = htmlString;
      newFile.comments = comments;
      newFile.suggestions = suggestions;
      let isOriginalFile = false;
      let inputMethod = 'UPLOAD';
      const fileSizeBytes = fileInfo.size;

      // Validate: does prior file exists?
      const ancestorDisplayId = !isOriginalFile ? this.activeFileVersionDisplayId : null;

      const documentMetadata = {
        fileVersionDisplayId,
        suggestions,
        comments,
        fileName,
        fileBase64,
        fileSizeBytes,
        isOriginalFile,
        ancestorDisplayId,
        inputMethod,
      };

      const respData = await this.ckeditorStore.storeFileVersionAndDocumentMetadata(documentMetadata);
      if (respData) {
        this.$emit('update-documentsharing', respData.document_sharing_uuid);
      }

      this.$emit('upload-file', {
        file: newFile,
        originalFileType: this.originalFileType,
        isNextStep: false,
      });
      this.$emit('sync-complete', true);
      this.agreementEditorStore.updateFileVersion(documentMetadata);
      return newFile;
    },

    async compressPdf(pdf_filename, pdf_base64) {
      let res = null;
      try {
        res = await this.$harbourData.post('data?agreementeditor-compresspdf', {
          requesttype: 'agreementeditor-compresspdf',
          pdf_base64: pdf_base64,
          pdf_filename: pdf_filename,
        });
      } catch (error) {
        console.log('compressPdf error', error);
        this.hasErrors = true;
        this.onFileRemoveClick();
        this.$emit('update-current-step', 0);
        throw error;
      }
      return res.data.pdfbase64;
    },

    async convertFileToPdf(fileInfo) {
      if (!fileInfo) return null;

      const fileName = fileInfo.name;
      const fileExt = fileName.split('.').pop().toLowerCase();

      const fileBlob = await getBlobFromUrl(fileInfo.originalUrl);
      const fileBase64 = await readFileAsDataUrl(fileBlob);

      try {
        this.isConvertingFileToPdf = true;

        const { converted } = await this.convertFile({
          fileBase64,
          fromType: fileExt,
          toType: 'pdf',
        });
        const convertedBase64NoMeta = converted.split(';base64,').pop();

        const pdfMimeType = 'application/pdf';
        const base64Meta = `data:${pdfMimeType};base64,`;

        const convertedBase64 = `${base64Meta}${convertedBase64NoMeta}`;
        const convertedBlob = await getBlobFromUrl(convertedBase64);
        const convertedFile = new File([convertedBlob], `${fileName.match(/([^/]*?)(?:\.[^.]*)?$/)[1]}.pdf`, {
          type: pdfMimeType,
        });

        const convertedFileInfo = {
          name: convertedFile.name,
          size: convertedFile.size,
          mimeType: convertedFile.type,
          base64: convertedBase64,
        };
        return convertedFileInfo;
      } catch (error) {
        this.$buefy.toast.open({
          message:
            'Unable to convert the file at this time. Please convert it to PDF and please try again',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        this.hasErrors = true;
        this.onFileRemoveClick();
        this.$emit('update-current-step', 0);
        console.error(error);
        throw error;
      } finally {
        this.isConvertingFileToPdf = false;
      }
    },

    async convertFileToDocx(fileInfo) {
      if (!fileInfo) return null;

      const fileName = fileInfo.name;
      const fileExt = fileName.split('.').pop().toLowerCase();

      const fileBlob = await getBlobFromUrl(fileInfo.originalUrl);
      const fileBase64 = await readFileAsDataUrl(fileBlob);

      try {
        this.isConvertingFileToDocx = true;

        const { converted } = await this.convertFile({
          fileBase64,
          fromType: fileExt,
          toType: 'docx',
        });
        const convertedBase64NoMeta = converted.split(';base64,').pop();

        const docxMimeType =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const base64Meta = `data:${docxMimeType};base64,`;

        const convertedBase64 = `${base64Meta}${convertedBase64NoMeta}`;
        const convertedBlob = await getBlobFromUrl(convertedBase64);
        const convertedFile = new File([convertedBlob], `${fileName.match(/([^/]*?)(?:\.[^.]*)?$/)[1]}.docx`, {
          type: docxMimeType,
        });

        const convertedFileInfo = {
          name: convertedFile.name,
          size: convertedFile.size,
          mimeType: convertedFile.type,
          base64: convertedBase64,
          file: convertedFile,
        };

        return convertedFileInfo;
      } catch (error) {
        this.$buefy.toast.open({
          message:
            'Unable to convert the file at this time. Please convert it to DOCX and try again',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        this.hasErrors = true;
        this.onFileRemoveClick();
        this.$emit('update-current-step', 0);
        console.error(error);

        throw error;
      } finally {
        this.isConvertingFileToDocx = false;
      }
    },

    async convertFile({ fileBase64, fromType, toType }) {
      const res = await this.$harbourData.post('agreementeditor-convertdocument', {
        fromtype: fromType,
        totype: toType,
        filebase64: fileBase64,
      });

      return res.data;
    },

    validateFile(fileInfo, checks) {
      const validationFunctions = [];
      if (checks.includes('MIMETYPE') == true) {
        validationFunctions.push(this.validateMimeType(this.acceptedFileTypes));
      }
      if (checks.includes('MINSIZE') == true) {
        validationFunctions.push(this.validateMinFileSize(5));
      }
      if (checks.includes('MAXSIZE') == true) {
        validationFunctions.push(this.validateMaxFileSize(this.maxFileUploadSizeMB * 1024 * 1024));
      }
      if (checks.includes('FILENAMELENGTH') == true) {
        validationFunctions.push(this.validateFileNameLength(200));
      }

      validationFunctions.forEach((validationFunc) => {
        validationFunc(fileInfo);
      });
    },

    validateMimeType(fileTypes) {
      return (fileInfo) => {
        if (fileInfo.mimeType === null) return;

        const mimeType = fileInfo.mimeType.split(';')[0]; // remove charset

        if (!fileTypes.includes(mimeType)) {
          this.$buefy.toast.open({
            message: 'Not accepted file type. Harbour only supports DOCX and PDF uploads',
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          this.hasErrors = true;
          this.onFileRemoveClick();
          this.$emit('update-current-step', 0);
          throw new Error('fileType');
        }
      };
    },

    validateFileNameLength(maxLength) {
      return (fileInfo) => {
        if (fileInfo.name === null) return;

        // causes an error because onedrive first generates a name
        // with a long string, and then it's normalized
        if (fileInfo.sourceInfo?.source === 'onedrive') {
          return;
        }

        if (fileInfo.name.length > maxLength) {
          this.$buefy.toast.open({
            message: 'File name is too big! Please try to make it shorten and try again',
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          this.hasErrors = true;
          this.onFileRemoveClick();
          this.$emit('update-current-step', 0);
          throw new Error('fileNameLength');
        }
      };
    },

    validateMinFileSize(minSizeByte) {
      return (fileInfo) => {
        if (fileInfo.size === null) return;

        if (fileInfo.size < minSizeByte) {
          this.$buefy.toast.open({
            message: `File is too small (<${minSizeByte} bytes). Please try again with a larger version`,
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          this.hasErrors = true;
          this.onFileRemoveClick();
          this.$emit('update-current-step', 0);
          throw new Error('fileMinimalSize');
        }
      };
    },

    validateMaxFileSize(maxSizeByte) {
      return (fileInfo) => {
        if (fileInfo.size === null) return;

        let fileComparisonSize = fileInfo.size;
        if (fileInfo.base64 != undefined && fileInfo.base64 != null) {
          fileComparisonSize = fileInfo.base64.length / 1.37; //*base64 overhead
        }
        const fileSizeMb = (fileInfo.size / (1024 * 1024)).toFixed(2);
        const maxSizeMb = (maxSizeByte / (1024 * 1024)).toFixed(2);

        if (fileComparisonSize > Number(maxSizeByte)) {
          this.$buefy.toast.open({
            message: `File size (original: ${fileSizeMb} MB) - exceeds the maximum size (${maxSizeMb} MB). Please try again with a smaller version`,
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          this.hasErrors = true;
          this.onFileRemoveClick();
          this.$emit('update-current-step', 0);
          throw new Error('fileMaximumSize');
        }
      };
    },

    validateFileExtension(type) {
      return (fileInfo) => {
        if (fileInfo.name === null) return;

        const fileName = fileInfo.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();

        if (type.toLowerCase() !== fileExtension.toLowerCase()) {
          this.$buefy.toast.open({
            message: `File is not of the required type (${type}). Harbour only supports .DOCX and PDF.`,
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          this.hasErrors = true;
          this.onFileRemoveClick();
          this.$emit('update-current-step', 0);
          throw new Error('fileExtension');
        }
      };
    },

    async storeCurrentFileVersion({
      fileVersionDisplayId,
      file,
      fileBase64,
      isOriginalFile = false,
      inputMethod = 'UPLOAD',
    }) {
      const fileName = file.name;
      const fileSizeBytes = file.size;

      // Validate: does prior file exists?
      const ancestorDisplayId = !isOriginalFile ? this.activeFileVersionDisplayId : null;

      try {
        this.isStoringFileVersion = true;

        // Save metadata if we have suggestions or comments
        if (this.originalFileType === 'word' || this.originalFileType === 'superdoc-word') {
          console.log('word document skip store file version -- done in the function');
        } else {
          let metaData = {
            fileVersionDisplayId,
            fileName,
            fileBase64,
            fileSizeBytes,
            isOriginalFile,
            ancestorDisplayId,
            inputMethod,
          };
          await this.agreementEditorStore.storeUpdateFileVersion(metaData);
        }

        if (isOriginalFile) {
          this.updateTopParentFileVersionDisplayId(fileVersionDisplayId);
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Unable to store file version - try again in a bit',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        console.error(error);
        this.hasErrors = true;
        this.onFileRemoveClick();
        this.$emit('update-current-step', 0);
        throw error;
      } finally {
        this.isStoringFileVersion = false;
      }
    },

    replaceUploadcareIcons() {
      const fileUseElem = document.querySelector('.uploadcare--menu__item_tab_file svg use');
      const gDriveUseElem = document.querySelector('.uploadcare--menu__item_tab_gdrive svg use');
      const dropboxUseElem = document.querySelector('.uploadcare--menu__item_tab_dropbox svg use');
      const oneDriveUseElem = document.querySelector(
        '.uploadcare--menu__item_tab_onedrive svg use',
      );
      const boxUseElem = document.querySelector('.uploadcare--menu__item_tab_box svg use');
      const blankUseElem = document.querySelector('.uploadcare--menu__item_tab_url svg use');

      const blankPageElem = document.querySelector('.uploadcare--menu__item_tab_url');
      blankPageElem?.setAttribute('title', 'Blank');

      fileUseElem?.setAttribute('xlink:href', '#hrbr-uploadcare--icon-file');
      gDriveUseElem?.setAttribute('xlink:href', '#hrbr-uploadcare--icon-gdrive');
      dropboxUseElem?.setAttribute('xlink:href', '#hrbr-uploadcare--icon-dropbox');
      oneDriveUseElem?.setAttribute('xlink:href', '#hrbr-uploadcare--icon-onedrive');
      boxUseElem?.setAttribute('xlink:href', '#hrbr-uploadcare--icon-box');
      blankUseElem?.setAttribute('xlink:href', '#hrbr-uploadcare--icon-addblank');

      if (blankPageElem) {
        blankPageElem.onclick = () => {
          this.onBlankDocumentClick();
        };
      }
    },

    async dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const blob = await fetch(dataurl).then((res) => res.blob());
      return new File([blob], filename, { type: mime });
    },

    async handleAgreementDataChange(agreementData) {
      const isCkeditorFile = !!this.ckeditorFileId;
      const hasAgreementData = !!(agreementData && agreementData.documentbase64);

      if (!hasAgreementData) {
        this.isLoadingData = false;
        this.$nextTick(() => {
          this.initUploadcareWidget();
        });
        return;
      }

      let fileName = 'Agreement.pdf';

      const containsDocTitle = !!agreementData.agreementdocumentjson?.documenttitle;
      if (containsDocTitle) {
        fileName = agreementData.agreementdocumentjson.documenttitle;
      }

      const fileData = {
        fileId: `pdf-${fileName.replace(/\W/g, '')}-${Math.random()
          .toString(36)
          .slice(2)}-${Date.now()}`,
        fileDocumentName: fileName.match(/([^/]*?)(?:\.[^.]*)?$/)[1],
        fileSizeBytes: 0,
        fileInputMethod: 'UPLOAD',
        fileBase64: this.templateBase64, // agreementData.documentbase64,
        fileName,
      };

      // handle editing of ckeditor agreement
      if (isCkeditorFile) {
        this.$emit('upload-file', { file: fileData, originalFileType: 'word', isNextStep: true });
        this.$emit('sync-complete', true);
      } else {
        this.$emit('upload-file', { file: fileData, originalFileType: 'pdf', isNextStep: true });
        const fileToUpload = await this.dataURLtoFile(agreementData.documentbase64, fileName);
        const upload = uploadcare.fileFrom('object', fileToUpload);
        upload.done((fileInfo) => {
          this.onFileUpload(fileInfo);
        });
      }
    },

    async getAddonPdfInfo() {
      const res = await this.$harbourData.post(
        'data?agreementeditor-getaddonstoredaddonpdfandinfo',
        {
          requesttype: 'agreementeditor-getaddonstoredaddonpdfandinfo',
          accesskey: this.addonKey,
        },
      );

      return res.data;
    },

    storeImportedFileDocx(resultData) {
      const docxBase64 = resultData.docxbase64;
      const docxBase64NoMeta = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${docxBase64
        .split(';base64,')
        .pop()}`; // #*always keep specific file metadata prepended

      // Convert base64 to docx file
      fetch(docxBase64NoMeta)
        .then((res) => res.blob())
        .then((blob) => {
          const docxFile = new File([blob], 'agreement.docx', {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
          // Store file version
          this.isStoringFileVersion = true;
          const fileVersionDisplayId = this.agreementEditorStore.generateFileVersionDisplayId();
          this.updateFileVersionDisplayId(fileVersionDisplayId);
          this.storeCurrentFileVersion({
            fileVersionDisplayId: fileVersionDisplayId,
            file: docxFile,
            fileBase64: docxBase64NoMeta,
          });
        });
    },

    // Import PDF from addon
    async importPdfFile() {
      console.log('-- addon import found');

      // Get just-stored PDF from server
      try {
        this.isLoadingData = true;
        const responseData = await this.getAddonPdfInfo();
        // Get fields
        const isValidStore = responseData.isvalidstore;
        const resultData = responseData.resultdata;
        const resultMessage = responseData.resultmessage;

        // Validate: PDF and metadata available?
        if (isValidStore === false) {
          this.isLoadingData = false;
          this.$buefy.toast.open({
            duration: 5500,
            message: resultMessage,
            position: 'is-top',
            type: 'is-dark',
          });
          this.$nextTick(() => {
            this.initUploadcareWidget();
          });
          return;
        }

        // Store extra metadata and handle new file
        this.addonImportMetaDataJson = resultData.metdatadata;
        const pdfBase64 = resultData.pdfbase64;
        const pdfBase64NoMeta = `data:application/pdf;base64,${pdfBase64.split(';base64,').pop()}`;
        // Addon import input method
        this.currentInputMethod = 'ADDONIMPORT';
        const fileToUpload = await this.dataURLtoFile(pdfBase64NoMeta, 'agreement.pdf');
        const upload = uploadcare.fileFrom('object', fileToUpload);
        upload.done((fileInfo) => {
          this.onFileUpload(fileInfo);
        });

        // Convert base64 to pdf file
        this.isImportingPdfFromAddon = false;

        this.storeImportedFileDocx(resultData);
      } catch (error) {
        this.isLoadingData = false;
        this.$buefy.toast.open({
          message: 'Unable to import your file - try again in a bit',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        this.hasErrors = true;
        this.onFileRemoveClick();
        this.$emit('update-current-step', 0);
        console.log('#importPdfFile - response error:', error);
        this.$nextTick(() => {
          this.initUploadcareWidget();
        });
      }
    },

    onConvertToPdfChange() {
      this.$emit('convert-to-pdf-change', this.isConvertToPdf);
    },

    startFromTemplate() {
      this.templatesStore.setActiveTemplateGroup('TEMPLGROUP-ALL-TEMPLATES');
      this.$router.push({ name: 'templates' });
      this.$emit('modal-close', {});
    },

    openPricingTableModal() {
      this.$emit('open-pricing-table-modal');
    },

    toggleDocumentSource() {
      this.templatesStore.isTemplatesSource = !this.templatesStore.isTemplatesSource;
      this.templatesStore.updateSavedSettings();
    },

    onRecentTemplateUploadingFile(val) {
      this.isUploadingFile = val;
    },

    async handlePreloadedFile() {
      this.isUploadingFile = true;
      const blob = await getBlobFromUrl(`/magic-sign/${this.preloadedFileId}`);
      const extension = blob.type === 'application/pdf' ? '.pdf' : '.docx';
      const file = new File([blob], blob.name || `Agreement${extension}`, { type: blob.type });

      let info = {
        mimeType: blob.type,
        size: file.size,
        originalUrl: `/magic-sign/${this.preloadedFileId}`,
        name: file.name,
        sourceInfo: {
          file
        }
      };

      if (blob.type !== 'application/pdf') {
        info = await this.convertFileToPdf(info);
      } else {
        const base64 = await readFileAsDataUrl(blob);
        info.base64 = base64;
      }
      this.handleUploaderNewFile(info);
    }
  },

  created() {
    this.isLoadingData =
      (this.activeTemplateId !== null || this.addonKey !== null) && this.isUploaderStepFirstLoad;

    this.isTemplateCreationMode = this.creationMode === 'template';

    this.$parent.$on('agreement-data-change', this.handleAgreementDataChange);
    if (this.addonKey) this.importPdfFile();
  },

  mounted() {
    this.initUploadcareWidget();

    if (this.isOnlySigner && this.preloadedFileId) {
      this.handlePreloadedFile();
    }

    if (this.isBlankCkDocument) this.blankDocumentHandler();

    const isDocumentFromFile = this.isCkDocumentFromFile || this.isPdfDocumentFromFile;
    if (isDocumentFromFile) {
      this.fromFileDocumentHandler(this.fileToUpload, this.isPdfDocumentFromFile);
    }
  },
};
</script>

<template>
  <div class="hrbr-agreement-editor-uploader" id="hrbr-agreement-editor-uploader">
    <div v-show="isUploadingFile">
      <b-loading :is-full-page="false" v-model="isUploadingFile" :can-cancel="false"></b-loading>
    </div>

    <!-- upgrade suggestion-->
    <template v-if="billingStore.isAgreementLimitReached">
      <!-- upgrade cap -->
      <div class="upgrade-invitation-block">
        <div class="upgrade-invitation-block__icon">
          <i class="fa-thin fa-circle-up"></i>
          <div class="upgrade-invitation-block__icon-boat" v-html="harbourBoatIcon"></div>
        </div>
        <!-- upgrade message -->
        <div>You reached your limit of free agreements (5/5) - upgrade today to continue!</div>

        <!-- upgrade button -->
        <div style="margin-top: 15px">
          <b-button type="is-success" v-on:click="openPricingTableModal()">
            <!-- <i class="fa-light fa-up" style="padding-right: 3px"></i> -->
            <span>Upgrade to continue sailing</span>
          </b-button>
        </div>
      </div>
    </template>

    <div
      v-else
      class="hrbr-agreement-editor-uploader__content-wrap"
    >
      <div v-if="!showLoader" class="hrbr-agreement-editor-uploader__actions-container">
        <div class="hrbr-agreement-editor-uploader__switches-container">
          <input
            type="radio"
            class="hrbr-agreement-editor-uploader__switch-upload"
            id="switchUpload"
            @click="toggleDocumentSource"
            name="switchUploadSource"
            value="Upload"
            :checked="!templatesStore.isTemplatesSource ? 'checked' : ''" />
          <input
            type="radio"
            class="hrbr-agreement-editor-uploader__switch-template"
            id="switchTemplate"
            @click="toggleDocumentSource"
            name="switchUploadSource"
            value="Template"
            :checked="templatesStore.isTemplatesSource ? 'checked' : ''" />
          <label class="hrbr-agreement-editor-uploader__switch-upload-label" for="switchUpload"
          >Upload</label
          >
          <label class="hrbr-agreement-editor-uploader__switch-template-label" for="switchTemplate"
          >Template</label
          >
          <div class="hrbr-agreement-editor-uploader__switch-wrapper">
            <div class="switch">
              <div>Upload</div>
              <div>Template</div>
            </div>
          </div>
        </div>

        <b-checkbox
          v-model="isOnlySigner"
          class="upload-checkbox"
        >
          I am the only signer
          <i class="fa-light fa-circle-question hover-info"></i>
        </b-checkbox>
      </div>


      <div class="hrbr-agreement-editor-uploader__panel__loading" v-if="showLoader">
        <div class="hrbr-agreement-editor-uploader__loader">
          <div class="hrbr-agreement-editor-uploader__loader-icon">
            <i class="fa-light fa-spinner-third fa-spin"></i>
          </div>
          <div v-if="isConvertingFileToDocx" class="hrbr-agreement-editor-uploader__loader-text">
            Converting to DOCX...
          </div>
          <div v-if="isConvertingFileToPdf" class="hrbr-agreement-editor-uploader__loader-text">
            Converting to PDF...
          </div>
          <div
            v-else-if="addonKey && isLoadingData"
            class="hrbr-agreement-editor-uploader__loader-text">
            Importing file...
          </div>
          <div v-else-if="isLoadingData" class="hrbr-agreement-editor-uploader__loader-text">
            Loading {{ creationMode }} details...
          </div>
          <div v-else class="hrbr-agreement-editor-uploader__loader-text">Uploading file...</div>
        </div>
      </div>
      <div
        v-else
        class="hrbr-agreement-editor-uploader__content-container"
      >
        <div v-show="showUploadcareWidget && !templatesStore.isTemplatesSource">
          <div class="hrbr-agreement-editor-uploader__panel">
            <div>
              <div class="hrbr-agreement-editor-uploader__widget">
                <div ref="widgetElement"></div>
              </div>
            </div>
          </div>
          <!-- 4/5 signed -->
          <b-progress
            v-if="billingStore.isAgreementLimitWillReachInOne"
            :value="80"
            type="is-info"
            size="is-medium"
            show-value
            style="margin-top: 10px">
            4/5 completed agreements
          </b-progress>
          <div class="hrbr-agreement-editor-uploader__convert-to-pdf">
            <b-checkbox
              v-model="isConvertToPdf"
              :disabled="isOnlySigner"
              class="upload-checkbox"
              @input="onConvertToPdfChange"
            >
              Convert file to PDF
              <a
                class="hrbr-agreement-editor-uploader__convert-to-pdf-question-mark"
                href="https://knowledgebase.harbourshare.com/knowledge/convert-to-pdf-option"
                target="_blank">
                <i class="fa-light fa-circle-question hover-info"></i>
              </a>
            </b-checkbox>
            <b-checkbox
              v-if="!billingStore.isAgreementLimitReached"
              :disabled="isOnlySigner"
              class="hrbr-agreement-editor-uploader__create-template upload-checkbox"
              data-testid="uploader-create-template"
              v-model="isTemplateCreationMode"
              @input="updateCreationMode"
              >Create as a template
            </b-checkbox>
          </div>
        </div>
        <template v-if="templatesStore.isTemplatesSource">
          <div
            class="hrbr-agreement-editor-uploader__templates"
            v-if="templatesStore.isLoadingRecentTemplates">
            <div style="margin-top: 150px">
              <div
                class="hrbr-agreement-editor-uploader__loader-icon"
                style="justify-content: center">
                <i class="fa-light fa-spinner-third fa-spin"></i>
              </div>
              <div class="hrbr-agreement-editor-uploader__loader-text">
                Loading recent templates...
              </div>
            </div>
          </div>
          <div class="hrbr-agreement-editor-uploader__templates" v-else>
            <span class="hrbr-agreement-editor-uploader__templates-header"> Recent </span>
            <div class="hrbr-agreement-editor-uploader__templates-list">
              <div v-if="templatesStore.recentTemplates.length > 0">
                <HrbrAgreementEditorRecentTemplate
                  v-for="(template, index) in templatesStore.recentTemplates"
                  :key="index"
                  :template="template"
                  @uploading-file="onRecentTemplateUploadingFile"
                  @modal-close="() => $emit('modal-close', {})">
                </HrbrAgreementEditorRecentTemplate>

                <div
                  class="hrbr-agreement-editor-uploader__templates_viewall"
                  @click="startFromTemplate()">
                  View all templates
                </div>
              </div>
              <div v-else>
                <div class="hrbr-agreement-editor-uploader__no-templates-list">
                  <div class="hrbr-agreement-editor-uploader__no-templates-list-intro">
                    <b-icon style="align-items: baseline" pack="fad" icon="layer-group"></b-icon>
                    <div style="margin-left: 35px">
                      <span class="hrbr-agreement-editor-uploader__no-templates-list-welcome">
                        Welcome to your templates!
                      </span>
                      <span class="hrbr-agreement-editor-uploader__no-templates-list-welcome-2">
                        After you use a template, it will appear here for future quick use
                      </span>
                    </div>
                  </div>
                  <div style="margin-left: 40px">
                    <span style="display: block">To get started:</span>
                    <div
                      v-if="templatesStore.myTemplates.length > 0"
                      class="hrbr-agreement-editor-uploader__template-select-button"
                      title="Select from full template gallery">
                      <div
                        class="hrbr-agreement-editor-uploader__template-select"
                        @click="startFromTemplate()">
                        Select from full template gallery
                      </div>
                    </div>
                    <div
                      v-else
                      class="hrbr-agreement-editor-uploader__template-select-button"
                      title="Create your first template">
                      <div
                        class="hrbr-agreement-editor-uploader__template-select"
                        @click="
                          toggleDocumentSource();
                          isTemplateCreationMode = true;
                        ">
                        + Create your first template
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <svg width="0" height="0" style="position: absolute">
      <symbol
        viewBox="0 0 384 512"
        id="hrbr-uploadcare--icon-file"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
      </symbol>
      <symbol
        viewBox="0 0 48 48"
        id="hrbr-uploadcare--icon-gdrive"
        xmlns="http://www.w3.org/2000/svg">
        <path fill="#FFC107" d="M17 6L31 6 45 30 31 30z" />
        <path fill="#1976D2" d="M9.875 42L16.938 30 45 30 38 42z" />
        <path fill="#4CAF50" d="M3 30.125L9.875 42 24 18 17 6z" />
      </symbol>
      <symbol
        viewBox="0 0 48 48"
        id="hrbr-uploadcare--icon-dropbox"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#0061fe"
          d="M42 13.976L31.377 7.255 24 13.314 35.026 19.732zM6 25.647L16.933 32.055 24 26.633 13.528 19.969zM16.933 7.255L6 14.301 13.528 19.969 24 13.314zM24 26.633L31.209 32.055 42 25.647 35.026 19.732z" />
        <path
          fill="#0061fe"
          d="M32.195 33.779L31.047 34.462 29.979 33.658 24 29.162 18.155 33.646 17.091 34.464 15.933 33.785 13 32.066 13 34.738 23.988 42 35 34.794 35 32.114z" />
      </symbol>
      <symbol
        viewBox="0 0 48 48"
        id="hrbr-uploadcare--icon-onedrive"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#1565c0"
          d="M40.429,35.999c0,0,2.89-0.393,3.47-3.185C43.964,32.502,44,32.161,44,31.787 c0-0.233-0.015-0.454-0.044-0.665c-0.428-3.158-3.852-3.868-3.852-3.868s0.595-3.401-2.543-5.183c-3.138-1.78-6.005,0-6.005,0 s-1.678-3.401-6.222-3.401c-5.843,0-6.817,6.64-6.817,6.64S13,25.636,13,30.493C13,35.352,18.031,36,18.031,36L40.429,35.999 L40.429,35.999z" />
        <path
          fill="#1565c0"
          d="M11,30.493c0-4.395,3.286-6.319,5.875-6.945c0.898-2.954,3.384-6.878,8.46-6.878 c0.006,0,0.011,0.001,0.017,0.001c0.007,0,0.013-0.001,0.02-0.001c3.522,0,5.71,1.646,6.892,2.953 c0.65-0.191,1.448-0.343,2.347-0.343c0.004,0,0.007,0.001,0.011,0.001c0.003,0,0.006,0,0.01,0c0.02,0,0.039,0.004,0.059,0.004 C34.729,19,34.063,12,26.013,12c-5.503,0-7.446,4.691-7.446,4.691s-3.992-2.965-8.092,1.133c-2.105,2.104-1.619,5.338-1.619,5.338 S4,23.648,4,28.825C4.001,33.515,9.018,34,9.018,34h2.807C11.32,33.041,11,31.886,11,30.493z" />
      </symbol>
      <symbol viewBox="0 0 48 48" id="hrbr-uploadcare--icon-box" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#0161d5"
          d="M25.639,17.927c-3.074,0-5.822,1.846-7.278,4.537c-1.293-2.691-4.042-4.537-7.278-4.537c-1.773,0-3.554,0.663-4.847,1.678v-7.567l0,0c0-0.845-0.813-1.69-1.618-1.69C3.649,10.347,3,11.021,3,12.037l0,0v14.47l0,0c0,4.538,3.716,8.415,8.083,8.415c3.074,0,5.822-1.861,7.278-4.553c1.456,2.691,4.042,4.553,7.278,4.553c4.529,0,8.083-3.877,8.083-8.415C33.722,21.787,30.168,17.927,25.639,17.927 M11.083,31.553c-2.749,0-4.847-2.353-4.847-5.046c0-2.858,2.261-5.058,4.847-5.058s4.854,2.367,4.854,5.058C16.1,29.2,13.832,31.553,11.083,31.553z M25.639,31.553c-2.749,0-4.854-2.353-4.854-5.046c0-2.858,2.269-5.058,4.854-5.058c2.749,0,4.847,2.367,4.847,5.058C30.485,29.2,28.225,31.553,25.639,31.553z" />
        <path
          fill="#0161d5"
          d="M44.785,32.353l-4.251-5.892l4.251-5.89l0,0c0.494-0.677,0.33-1.859-0.328-2.354c-0.649-0.505-1.793-0.337-2.287,0.324l0,0l-3.594,5.061l-3.602-5.061l0,0c-0.486-0.661-1.637-0.829-2.286-0.324c-0.658,0.495-0.822,1.678-0.329,2.354l0,0l4.252,5.89l-4.252,5.892l0,0c-0.493,0.66-0.329,1.845,0.329,2.352c0.649,0.508,1.8,0.34,2.286-0.338l0,0l3.602-5.044l3.594,5.044l0,0c0.494,0.678,1.638,0.846,2.287,0.338C45.115,34.197,45.279,33.013,44.785,32.353L44.785,32.353" />
      </symbol>
      <symbol
        viewBox="0 0 576 512"
        id="hrbr-uploadcare--icon-addblank"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm16 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H448V304z" />
      </symbol>
    </svg>
  </div>
</template>

<style lang="postcss" scoped>
/* scoped styles */
.hrbr-agreement-editor-uploader {
  --panel-height: 400px;

  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0 24px 24px;
  height: 100%;
  overflow: auto;
  position: relative;

  &__content-wrap {
    width: 100%;
  }

  &__actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__switches-container {
    width: 328px;
    position: relative;
    display: flex;
    background: #F5F5F5;
    font-size: 14px;
    line-height: 40px;
    border-radius: 8px;
    cursor: pointer;

    input {
      visibility: hidden;
      position: absolute;
      top: 0;
    }

    label {
      width: 50%;
      padding: 0;
      margin: 0;
      text-align: center;
      cursor: pointer;
      color: #555555;
    }
  }

  &__switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .switch {
    border-radius: 8px;
    background: white;
    height: 100%;
    display: block;
    margin-right: 0;
    color: #333333;
    font-weight: 600;
  }

  &__content-container {
    margin-top: 20px;
  }

  .upload-checkbox {
    margin: 0;
    font-size: 14px;
    color: black;

    &:hover:deep(input[type='checkbox']:not(:disabled) + .check ) {
      border-color: #1355FF;
    }

    :deep(input[type='checkbox']:checked + .check) {
      border-color: #1355FF;
      background-color: #1355FF;
    }
    :deep(input[type=checkbox]:focus + .check) {
      box-shadow: none;
    }
    :deep(input + .check) {
      width: 20px;
      height: 20px;
      border-color: #CCCCCC;
      border-radius: 2px;
    }
  }

  &__convert-to-pdf {
    padding: 16px;
    margin-top: 12px;
    background-color: #F5F5F5;
    border-radius: 8px;
    .upload-checkbox + .upload-checkbox {
      margin-top: 8px;
    }
    label {
      display: flex;
    }
  }

  &__panel,
  &__panel__loading {
    width: 100%;
    max-width: 800px;
    height: var(--panel-height);
    border: 2px dashed #1355FF;
    border-radius: 8px;
    overflow: hidden;
    transition: background 0.2s;
  }

  &__templates-header {
    font-weight: 600;
    color: #000000;
    font-size: 16px;
  }

  &__templates {
    width: 100%;
    max-width: 800px;
    height: var(--panel-height);
    overflow: hidden;
    border: 2px dashed #1355FF;;
    border-radius: 8px;
    padding: 15px;
  }
}

:deep(.uploadcare) {
  &--panel {
    flex-direction: column;
    height: calc(var(--panel-height) - 4px); /* 4px - border-width */
    border: none;
    background-color: transparent;
  }

  &--text_muted {
    display: none;
  }

  &--menu {
    width: 100%;
    height: 60px;
    margin-top: 25px;
  }

  &--text_size_extra-large {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    color: #000000;
  }

  &--tab__action-button {
    padding: 10px 18px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    background: #1355FF;
    border: 0;
    outline: 0;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #0133B7;
    }
  }

  &--menu__items {
    flex-direction: row;
    justify-content: center;
    padding-right: 0;
    background: transparent;
  }

  &--menu__item {
    color: #333;
    border-radius: 8px;
    cursor: pointer;
    border: #dbdbdb solid 1px;
    margin-right: 10px;
    background: transparent;

    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: #DBDBDB;
    }
  }

  &--menu__item_current {
    background-color: #E2E9FB !important;
    &:hover {
      background-color: #DBDBDB !important;
    }
  }

  &--menu__icon {
    width: 40px;
    height: 40px;
    margin: 10px;
  }

  &--menu__item_tab_file .uploadcare--menu__icon {
    color: #6d6d73;
    height: 32px;
  }

  &--menu__item_tab_url .uploadcare--menu__icon {
    color: #6d6d73;
    height: 28px;
    margin-top: 12px;
    margin-bottom: 0;
  }

  &--menu__item_tab_url {
    display: block;
    &:after {
      color: #333;
      font-size: 10px;
      font-weight: 400;
      line-height: 7px;
      margin-left: 16px;
    }
  }

  &--panel__powered-by {
    display: none !important;
  }
}

.upgrade-invitation-block {
  text-align: center;
  padding-top: 20px;

  &__icon {
    display: flex;
    justify-content: center;
    width: 190px;
    height: 190px;
    font-size: 190px;
    color: #1355ff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    position: relative;
  }

  &__icon-boat {
    width: 62px;
    height: 62px;
    position: absolute;
    bottom: -6px;

    :deep(svg) {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@media all and (max-width: 759px) {
  .hrbr-agreement-editor-uploader {
    &__templates-list {
      margin: 0;
    }

    &__settings-checkbox {
      margin-top: 0;
    }

    &__actions-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  :deep(.uploadcare) {
    &--panel {
      justify-content: flex-start;
    }

    &--menu {
      margin-top: 0;
    }

    &--menu_opened {
      height: 100%;
    }

    &--menu__items {
      flex-direction: column;
      justify-content: flex-start;
    }

    &--menu__item {
      width: 100%;
      border-radius: 0;
    }

    &--menu__item_current {
      color: #353535;
      background: none;

      &:active,
      &:focus,
      &:hover {
        color: #353535;
        background: none;
      }
    }

    &--menu__icon {
      width: 32px;
      height: 32px;
      margin: 14px;
    }
  }
}
</style>

<style lang="postcss">
/* global styles */
body.uploadcare--dragging {

  .hrbr-agreement-editor-uploader__panel {
    border-color: #0133b7;
  }

  .uploadcare--menu[data-current="file"] {
    display: none;
  }

  .uploadcare--draganddrop {
    margin: 0;
    border: 0;
    border-radius: 0;
  }
}
</style>

<style>
/* old styles */
#hrbr-agreement-editor-uploader
  .hrbr-agreement-editor-uploader__switches-container
  .hrbr-agreement-editor-uploader__switch-upload-label:hover,
#hrbr-agreement-editor-uploader
  .hrbr-agreement-editor-uploader__switches-container
  .hrbr-agreement-editor-uploader__switch-template-label:hover {
  color: black;
}


#hrbr-agreement-editor-uploader .switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: #4a4a4a;
  transition: opacity 0.1s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: -2px;
  left: 0;
}

#hrbr-agreement-editor-uploader
  .hrbr-agreement-editor-uploader__switches-container
  input:nth-of-type(1):checked
  ~ .hrbr-agreement-editor-uploader__switch-wrapper {
  transform: translateX(0%);
}

#hrbr-agreement-editor-uploader
  .hrbr-agreement-editor-uploader__switches-container
  input:nth-of-type(2):checked
  ~ .hrbr-agreement-editor-uploader__switch-wrapper {
  transform: translateX(100%);
}

#hrbr-agreement-editor-uploader
  .hrbr-agreement-editor-uploader__switches-container
  input:nth-of-type(1):checked
  ~ .hrbr-agreement-editor-uploader__switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

#hrbr-agreement-editor-uploader
  .hrbr-agreement-editor-uploader__switches-container
  input:nth-of-type(2):checked
  ~ .hrbr-agreement-editor-uploader__switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}

#hrbr-agreement-editor-uploader .start-from-blank-btn {
  text-decoration: underline;
  cursor: pointer;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__settings {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__template-gallery-button,
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__start-from-blank-button,
.hrbr-agreement-editor-uploader__settings .fal {
  font-size: 13px;
  font-weight: 500;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__start-from-blank-button {
  margin-right: 1em;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__settings .button {
  border-color: #167df0;
  border-width: 1px;
  color: #167df0;
  transition: border 0.18s;
  padding-left: 20px;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__settings .button:hover {
  border: 1px solid #2d71ad;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__settings .button:hover span {
  color: #2d71ad;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__templates-list {
  margin: 10px 40px 20px;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__no-templates-list {
  border: 2px solid #dadada;
  height: 250px;
  border-radius: 4px;
  background: #00000014;
  overflow: auto;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__no-templates-list-intro {
  margin: 35px;
  display: flex;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__no-templates-list-welcome {
  display: block;
  font-weight: 600;
  color: black;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__no-templates-list-welcome-2 {
  display: block;
  color: #6d6d73;
}
#hrbr-agreement-editor-uploader .fad.fa-layer-group {
  font-size: 50px;
  color: var(--main-primary-color);
}


#hrbr-agreement-editor-uploader
  .hrbr-agreement-editor-uploader__panel:has(
    [data-current='gdrive'],
    [data-current='dropbox'],
    [data-current='onedrive']
  ) {
  background: transparent;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__file {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

#hrbr-agreement-editor .hover-info {
  color: #0000008c;
  font-weight: 300;
  font-size: 11px;
  margin-left: 2px;
}

#hrbr-agreement-editor .hover-info:hover {
  color: #000000;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__file-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: relative;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__file-icon {
  display: flex;
  font-size: 75px;
  color: #48c78e;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__file-name {
  font-size: 21px;
  line-height: 1.2;
  text-align: center;
  max-width: 220px;
  word-break: break-all;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__file-remove {
  font-size: 24px;
  color: #333;
  display: flex;
  position: absolute;
  bottom: 100%;
  right: 0;
  cursor: pointer;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__convert-to-pdf-question-mark {
  font-size: 12px;
  text-align: center;
  vertical-align: baseline;
  cursor: pointer;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__error-icon {
  font-size: 75px;
  color: #f14668;
  display: flex;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__error-text {
  font-size: 21px;
  line-height: 1.2;
  text-align: center;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-uploader__error-button {
  font-family: inherit;
  font-size: 16px;
  color: #fff;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  background: var(--main-primary-color);
  border: none;
  outline: none;
  appearance: none;
  transition: all 0.2s ease;
}

#hrbr-agreement-editor-modal .hrbr-agreement-editor-uploader__error-button:hover {
  background: var(--main-primary-color-activefocus);
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: auto;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__loader-icon {
  font-size: 75px;
  color: var(--main-primary-color);
  display: flex;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__loader-icon .fa-spin {
  animation-duration: 0.5s;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__loader-text {
  font-size: 21px;
  line-height: 1.2;
  text-align: center;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__settings-checkbox {
  margin-top: 5px;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__templates_viewall {
  text-decoration: underline;
  color: rgb(45, 113, 173);
  display: flex;
  justify-content: flex-end;
  margin: 0px 40px 40px;
  cursor: pointer;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__template-select {
  line-height: 17px;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__template-select-button {
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: white;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 300px;
  height: 50px;
  margin: 11px 0px 2px 2px;
}

.hrbr-agreement-editor-uploader__dropdown-item {
  margin-top: 1px;
  cursor: pointer;
  margin-right: 3px;
  margin-left: 0px;
  color: #acacac;
}

.hrbr-agreement-editor-uploader__dropdown-item:hover {
  color: black;
}
#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__template-edit-button {
  padding: 5px;
  text-align: center;
  border-radius: 0 4px 4px 0;
  border: 1px solid #ddd;
  cursor: pointer;
  color: #6a6a6a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  max-height: 32px;
  margin: 10px 10px 2px 0px;
}

#hrbr-agreement-editor-uploader .hrbr-agreement-editor-uploader__template-edit-button:hover {
  border: 1px solid #b5b5b5;
  color: black;
}
#hrbr-agreement-editor-uploader .fa-spin {
  animation-duration: 0.5s;
}
</style>
