
<script>
import { DraftTypes } from '@/domain/drafts/draft';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDraftsStore } from '../../stores/drafts-store';
import { useCkeditorStore } from '@/components/Ckeditor/stores/ckeditor-store';
import { useSuperdocStore } from '@/stores/superdoc-store';
import HrbrDraftsTitleModal from '../HrbrDraftsTitleModal.vue';

export default {
  name: 'DraftsActionsRenderer',

  data() {
    return {
      isCopyingDraft: false,
    };
  },

  setup() {
    const harbourStore = useHarbourStore();
    const draftsStore = useDraftsStore();
    const ckeditorStore = useCkeditorStore();
    const superdocStore = useSuperdocStore();
    return {
      harbourStore,
      draftsStore,
      ckeditorStore,
      superdocStore
    };
  },

  computed: {
    draft() {
      return this.params.data;
    },
    isPdfType() {
      return this.draft.type === DraftTypes.Pdf;
    },
    isUserOwner() {
      return !this.draft.isShared;
    },
    isSuperdoc() {
      return this.params.data.type === DraftTypes.SuperDoc;
    },
    canPerformAction() {
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isOrgAdmin;
    },
    canCopyLink() {
      if (this.isPdfType) return false;
      return true;
    },
    canShare() {
      if (this.isPdfType) return false;
      const systemEmail = this.harbourStore.contextDict.systememail;
      const isWriter = this.draft.writer.includes(systemEmail);
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isWriter || isOrgAdmin;
    },
  },

  methods: {
    openCkShareModal({ ckFileId, name }) {
      this.$openCkeditorShareModal({
        props: {
          fileDisplayId: ckFileId,
          agreementName: name,
        },
      });
    },

    onShareButtonClick() {
      if (!this.canShare) return;

      if (this.isSuperdoc) {
        this.superdocStore.openSuperdocShareModal(this.draft, this);
      } else {
        this.openCkShareModal({
          ckFileId: this.draft.ckFileId,
          name: this.draft.name,
        });
      }
    },

    onCopyLinkButtonClick() {
      if (!this.canCopyLink) return;
      this.draftsStore.copySharedLinkWithNotify(this.draft);
    },

    onDeleteButtonClick() {
      if (!this.canPerformAction) return;
      this.draftsStore.deleteDraftWithConfirm(this.draft.id);
    },

    onDuplicateButtonClick() {
      if (!this.canPerformAction || this.isCopyingDraft) return;

      const initialTitle = `Copy of ${this.draft.name}`;
      this.$buefy.modal.open({
        parent: this,
        component: HrbrDraftsTitleModal,
        props: {
          initialTitle,
        },
        hasModalCard: true,
        canCancel: true,
        width: 320,
        events: {
          save: ({ title }) => {
            this.copyDraft(title);
          },
        },
      });
    },

    async copyDraft(name) {
      this.isCopyingDraft = true;
      const copyData = await this.draftsStore.copyDraft({
        draft: this.draft,
        name,
      });

      if (!copyData) {
        this.isCopyingDraft = false;
        return;
      }
      this.isCopyingDraft = false;

      const draftId = copyData.draft.id;
      await new Promise((res) => setTimeout(res, 500));
      await this.highlightDraftRow(draftId);

      this.draftsStore.openDraftDocument({
        draft: copyData.draft,
        htmlData: copyData.htmlData,
        parentComponent: this,
      });
    },

    async highlightDraftRow(draftId) {
      const rowElems = [...document.querySelectorAll(`.ag-row[row-id="${draftId}"]`)];
      const highlitedClass = 'ag-row--highlited';

      rowElems.forEach((row) => row.classList.add(highlitedClass));
      await new Promise(res => setTimeout(res, 350));
      rowElems.forEach((row) => row.classList.remove(highlitedClass));
    },
  },
};
</script>

<template>
  <div class="draft-actions-columns">
    <div class="draft-actions-columns__container">
      <button
        class="draft-actions-columns__button draft-actions-columns__button--1"
        :disabled="!canPerformAction || isCopyingDraft || isSuperdoc"
        @click="onDuplicateButtonClick">
        <span class="draft-actions-columns__copy-spinner" v-if="isCopyingDraft">
          <i class="fa-regular fa-spinner-third fa-spin"></i>
        </span>
        <template v-else>
          <i class="fa-regular fa-files"></i>
          Duplicate
        </template>
      </button>
      <button
        class="draft-actions-columns__button draft-actions-columns__button--2"
        :disabled="!canCopyLink"
        @click="onCopyLinkButtonClick">
        <i class="fa-regular fa-link-simple"></i>
        Copy link
      </button>
      <button
        class="draft-actions-columns__button draft-actions-columns__button--3"
        :disabled="!canShare"
        @click="onShareButtonClick">
        <i class="fa-regular fa-user-plus"></i>
        Share
      </button>
      <b-dropdown
        class="draft-actions-columns__dropdown"
        position="is-bottom-left"
        animation=""
        append-to-body
        :triggers="['click']"
        :mobile-modal="false">
        <template #trigger>
          <i class="fa-regular fa-ellipsis-vertical"></i>
        </template>
        <template #default>
          <b-dropdown-item
            :disabled="!canPerformAction"
            @click="onDeleteButtonClick">
            <i class="fa-regular fa-trash"></i>
            <span>Delete</span>
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.draft-actions-columns {
  display: flex;
  justify-content: center;
  padding: 0 5px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    white-space: nowrap;
    color: #333;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 34px;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #DBDBDB;
    background: #fff;
    outline: none;
    appearance: none;
    transition: all 0.2s ease;

    &:hover {
      background: #DBDBDB;
    }

    &:disabled,
    &[disabled] {
      color: #ccc;
      cursor: not-allowed;
      pointer-events: none;
    }

    &--1 {
      border-right: none!important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &--2 {
      border-radius: 0;
    }
    &--3 {
      border-left: none!important;
      border-radius: 0;
    }
  }

  &__dropdown {
    width: 34px;
    height: 34px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    overflow: hidden;

    /* override */
    border-left: none!important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    :deep(.dropdown-trigger) {
      font-size: 15px;
      color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #fff;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: #DBDBDB;
      }
    }
  }

  &__copy-spinner {
    display: inline-flex;
    justify-content: center;
    min-width: 79px;

    i {
      animation-duration: 0.5s;
    }
  }
}
</style>

<style lang="postcss">
.draft-actions-columns {
  &__dropdown {
    .dropdown-item {
      font-size: 14px;
      font-weight: 500;
      color: #333;
      display: flex;
      align-items: center;
      gap: 6px;

      i {
        display: inline-flex;
        justify-content: center;
        width: 20px;
      }
    }
  }
}
</style>
